<template>
  <v-container class="all-screen d-flex align-center justify-center">
    <div class="text-center" v-if="releaseNotes == true">
      <v-dialog content-class="modalVs" v-model="releaseNotes" width="800" no-click-animation :retain-focus="false">
        <release-notes></release-notes>
      </v-dialog>
    </div>

    <div class="text-center" v-if="inactiveAccount">
      <v-overlay opacity="1" :value="inactiveAccount">
        <v-card style="background-color: #31394c !important" width="500">
          <v-card-title class="d-flex justify-center">
            <p class="h5-text">
              El equipo de Vanguard Vision se comunicará con usted cuando
              la cuenta se encuentre habilitada.
            </p>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cancel" outlined text @click="clearLoginView" rounded>
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </div>

    <div class="text-center" v-if="firstTimeSession == true">
      <v-overlay opacity="1" :value="firstTimeSession">
        <v-card style="background-color: #31394c !important" width="500">
          <v-card-title class="d-flex justify-center">
            <p>Cambio de contraseña</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="12" class="my-0 py-0">
                <v-text-field
                  type="password"
                  label="Nueva contraseña"
                  outlined
                  dark
                  color="secondary"
                  v-model="newPassword"
                ></v-text-field>
              </v-col>
              <v-col md="12" class="my-0 py-0">
                <v-text-field
                  type="password"
                  label="Repita nueva contraseña"
                  outlined
                  dark
                  color="secondary"
                  v-model="validationNewPassord"
                ></v-text-field>
              </v-col>
              <v-col md="12" class="my-0 py-0">
                <v-btn
                  block
                  color="primary"
                  rounded
                  large
                  class="px-4"
                  @click="
                    changeFirstTimeSessionPassword(
                      newPassword,
                      validationNewPassord
                    )
                  "
                  >Cambiar contraseña</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <div class="ctLogin">
      <div>
        <img src="../assets/logo.png" />
      </div>
      <br />
      <p id="message"></p>
      <!-- login -->
      <form id="formLogin" name="formLogin" v-if="actionForm1">
        <div>
          <v-text-field
            label="Usuario"
            outlined
            dark
            color="secondary"
            required
            v-model="userCredentials.username"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            :append-icon="showPasswordIcon ? 'fal fa-eye' : 'fal fa-eye-slash'"
            :type="showPasswordIcon ? 'text' : 'password'"
            name="input-10-2"
            label="Contraseña"
            outlined
            dark
            required
            color="secondary"
            class="input-group--focused"
            @click:append="showPasswordIcon = !showPasswordIcon"
            v-model="userCredentials.password"
            v-on:keyup.enter="logIn(userCredentials)"
          ></v-text-field>
        </div>
        <p
          class="olvPass"
          v-on:click="
            actionForm1 = false;
            actionForm2 = true;
          "
        >
          ¿Olvidaste tu contraseña?
        </p>
        <br />
        <div>
          <v-btn
            block
            color="primary"
            rounded
            large
            class="px-4"
            @click="logIn(userCredentials)"
            >Iniciar Sesión</v-btn
          >
        </div>
        <div class="text-center ma-0 py-5">
          <small @click="showReleaseNotes" style="color: #68789e; cursor: pointer">© V 1.0 - Vanguard Vision AI</small>
        </div>
      </form>
      <!-- recover password -->
      <form id="formRecover" name="formRecover" v-if="actionForm2">
        <div>
          <v-text-field
            label="Nombre de usuario."
            outlined
            dark
            color="secondary"
            required
            v-model="recoveryPassUsername"
          ></v-text-field>
        </div>
        <p
          class="olvPass"
          v-on:click="
            actionForm1 = true;
            actionForm2 = false;
          "
        >
          Iniciar sesión
        </p>
        <br />
        <div>
          <button
            class="btnOne"
            @click="sendEmailCode(recoveryPassUsername)"
            style="width: calc(100% - 10px)"
          >
            Enviar
          </button>
        </div>
        <br />
      </form>
      <!-- recover password second step -->
      <form
        id="formRecoverSecondStep"
        name="formRecoverSecondStep"
        v-if="actionForm3"
      >
        <div>
          <v-text-field
            label="Código"
            outlined
            dark
            color="secondary"
            type="number"
            max="999999"
            required
            v-model="recoveryPassCode"
          ></v-text-field>
        </div>
        <br />
        <div>
          <v-text-field
            :append-icon="showPasswordIcon ? 'fal fa-eye' : 'fal fa-eye-slash'"
            :type="showPasswordIcon ? 'text' : 'password'"
            name="input-10-2"
            label="Nueva Contraseña"
            outlined
            dark
            required
            color="secondary"
            class="input-group--focused"
            @click:append="showPasswordIcon = !showPasswordIcon"
            v-model="recoveryNewPass"
          ></v-text-field>
        </div>
        <p
          class="olvPass"
          v-on:click="
            actionForm1 = true;
            actionForm2 = false;
            actionForm3 = false;
          "
        >
          Iniciar sesión
        </p>
        <br />
        <div>
          <v-btn
            @click="
              changePassword(
                recoveryPassUsername,
                recoveryPassCode,
                recoveryNewPass
              )
            "
            style="width: calc(100% - 10px)"
          >
            Enviar
          </v-btn>
        </div>
        <br />
      </form>
      <v-snackbar v-model="snackbar" timeout="4000" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
  <!-- <v-container class="all-screen">
    <v-row class="all-screen d-flex align-center justify-center">
      <v-col cols="12" md="6">
        <v-card elevation="24">
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <v-img class="login-img"
                  max-height="200"
                  src="../assets/vvai.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="USUARIO"
                  v-model="userCredentials.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :append-icon="showPasswordIcon ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordIcon ? 'text' : 'password'"
                  name="input-10-2"
                  label="CONTRASEÑA"
                  class="input-group--focused"
                  @click:append="showPasswordIcon = !showPasswordIcon"
                  v-model="userCredentials.password"
                  v-on:keyup.enter="logIn(userCredentials)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  outlined
                  class="text-uppercase"
                  color="primary darken-3"
                  @click="logIn(userCredentials)"
                  >Iniciar Sesión</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <small>© v1.11.0 by Vanguard Technologies</small>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar" timeout="4000" :color="snackbarColor">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
    </v-row>
  </v-container> -->
</template>

<script>
import axios from "axios";
import auth from "../services/auth";
import globals from "../globals";
import backlog from '../services/logs'
import { Auth } from "aws-amplify";
import ReleaseNotes from "./release-notes.vue";

export default {
  name: "LogInComponent",
  components: {
    ReleaseNotes
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    showPasswordIcon: false,
    isLogged: auth.loggedIn(),
    firstTimeSession: false,
    firstTimeObj: {},
    newPassword: "",
    validationNewPassord: "",
    userCredentials: {
      username: "",
      password: "",
    },
    recoveryPassUsername: "",
    recoveryPassCode: "",
    recoveryNewPass: "",
    inactiveAccount: false,
    actionForm1: true,
    actionForm2: false,
    actionForm3: false,
    releaseNotes: false
  }),
  methods: {
    showReleaseNotes() {
      this.releaseNotes = true
    },

    changeFirstTimeSessionPassword(newPassword, validationNewPassword) {
      if (validationNewPassword === newPassword) {
        Auth.completeNewPassword(
          this.firstTimeObj,
          newPassword
          /* { // TODO: Se debe validar como vamos a hacer el acceso a la plataforma con un usuario nuevo que no tiene custom attributes como asset_id y rol.
              "phone_number": "+543217808661"
            } */
        )
          .then((user) => {
            console.log("RESPUESTA DE NUEVO INICIO DE SESION! ", user);
            this.inactiveAccount = true;
            this.actionForm1 = false;
            this.actionForm2 = false;
            this.actionForm3 = false;
            this.firstTimeSession = false;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "red";
        this.snackbarText = "La contraseña no coincide.";
      }
    },

    logIn(userCredentials) {
      if (userCredentials.username != "" && userCredentials.password != "") {
        Auth.signIn({
          username: userCredentials.username,
          password: userCredentials.password,
        })
          .then((res) => {
            if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
              this.firstTimeSession = true;
              this.firstTimeObj = res;
            } else {
                let auth_info = {
                  //asset_id: "5c6990eb-2bd3-44e5-8c10-3014da089386",
                  asset_id: res.attributes["custom:asset_id"],
                  asset_sede_name: res.attributes["custom:assetName"],
                  asset_city: res.attributes["custom:city"],
                  asset_company_name: res.attributes["custom:company"],
                  rol: res.attributes["custom:rol"],
                  module: res.attributes["custom:module"],
                  name: res.attributes.name,
                  email: res.attributes.email,
                  sub: res.attributes.sub,
                  username: res.username,
                };
                let nameSplited = auth_info.name.split(" ");
                localStorage.setItem("access-token", auth_info.sub);
                localStorage.setItem("id", auth_info.sub);
                localStorage.setItem("enterpriseID", auth_info.asset_id); // TODO: Change or delete to use only one of the local storage asset ID.
                localStorage.setItem("Vang_asset", auth_info.asset_id);
                localStorage.setItem("Vang_rol", auth_info.rol);
                localStorage.setItem("Vang_org", auth_info.asset_company_name);
                localStorage.setItem("Vang_sede", auth_info.asset_sede_name);
                localStorage.setItem("enterpriseName", auth_info.asset_company_name.toLowerCase());
                localStorage.setItem("username", auth_info.username);
                localStorage.setItem("name", nameSplited[0]);
                localStorage.setItem("lastname", nameSplited[1]);
                localStorage.setItem("role", auth_info.rol.toUpperCase());
                localStorage.setItem("role-name", auth_info.rol.toUpperCase());
                localStorage.setItem("modules", auth_info.module);
                this.userCredentials.username = "";
                this.userCredentials.password = "";
                this.$bus.$emit("logged", "User Logged");
                backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "login"})
                if (auth_info.rol.toUpperCase() == "IOT") {
                  this.$router.replace({ name: "dashIOT" });
                } else if (auth_info.rol.toUpperCase() == "IMG") {
                  this.$router.replace({ name: "dashImgs" });
                } else {
                  if (auth_info.module == 4) {
                    //localStorage.setItem("modules", 4);
                    this.$router.replace({ name: "vision" });
                  } else {
                    this.$router.replace({ name: "mainModule" });
                  }
                }
              // other situations
              
              //this.validateOrg(auth_obj);
            }
          })
          .catch((error) => {
            console.log("error signing up:", error.message);
            this.snackbarText = error.message;
            this.snackbarColor = "red";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Los campos son obligatorios.";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },

    sendEmailCode(username) {
      if (username) {
        Auth.forgotPassword(username)
          .then((data) => {
            console.log("Recuperacion pass: ", data);
            this.actionForm2 = false;
            this.actionForm3 = true;
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText =
              "Por favor valide que esté bien escrito el username.";
            this.snackbarColor = "red";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Es necesario que ingrese el nombre de usuario.";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },

    changePassword(username, code, new_password) {
      Auth.forgotPasswordSubmit(username, code, new_password)
        .then((data) => {
          if (data) {
            this.clearLoginView();
            this.snackbarText = "Se modificó su contraseña.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        })
        .catch((err) => console.log(err));
    },

    clearLoginView() {
      this.showPasswordIcon = false;
      this.firstTimeSession = false;
      this.firstTimeObj = {};
      this.newPassword = "";
      this.validationNewPassord = "";
      this.userCredentials.username = "";
      this.userCredentials.password = "";
      this.recoveryPassUsername = "";
      this.recoveryPassCode = "";
      this.recoveryNewPass = "";
      this.inactiveAccount = false;
      this.actionForm1 = true;
      this.actionForm2 = false;
      this.actionForm3 = false;
    },

    validateOrg(auth_info) {
      if (auth_info.asset) {
        try {
          axios
            .all([
              axios({
                method: "GET",
                url: globals.APIURLVision + "/info/" + auth_info.asset,
                withCredentials: false,
              }),
              axios.post(globals.APIURL + "org/fo", {
                company_id: auth_info.asset_id,
              }),
            ])
            .then(
              axios.spread((response_vision, response_access) => {
                console.log("Empresa de Vision", response_vision);
                console.log("Empresa de Security", response_access);
                let nameSplited = auth_info.name.split(" ");
                localStorage.setItem("access-token", auth_info.sub);
                localStorage.setItem("id", auth_info.sub);
                localStorage.setItem("enterpriseID", auth_info.asset_id);
                localStorage.setItem("Vang_asset", auth_info.asset);
                localStorage.setItem("Vang_rol", auth_info.rol);
                localStorage.setItem(
                  "Vang_org",
                  response_vision.data.body[0].company
                );
                localStorage.setItem(
                  "Vang_sede",
                  response_vision.data.body[0].assetName
                );
                localStorage.setItem(
                  "enterpriseName",
                  response_access.data.name
                );
                localStorage.setItem("username", auth_info.username);
                localStorage.setItem("name", nameSplited[0]);
                localStorage.setItem("lastname", nameSplited[1]);
                localStorage.setItem("role", auth_info.rol.toUpperCase());
                localStorage.setItem("role-name", auth_info.rol.toUpperCase());
                this.userCredentials.username = "";
                this.userCredentials.password = "";
                this.$bus.$emit("logged", "User Logged");
                if (auth_info.rol.toUpperCase() == "IOT") {
                  this.$router.replace({ name: "dashIOT" });
                } else if (auth_info.rol.toUpperCase() == "IMG") {
                  this.$router.replace({ name: "dashImgs" });
                } else {
                  if (!response_access.data.name) {
                    localStorage.setItem("modules", 4);
                    this.$router.replace({ name: "vision" });
                  } else {
                    this.$router.replace({ name: "mainModule" });
                  }
                }
              })
            );
        } catch (error) {
          console.log(error);
        }
      } else {
        this.inactiveAccount = true;
      }

      /* axios
        .post(globals.APIURL + "org/fo", {company_id: auth_info.asset_id})
        .then(res => {
            let nameSplited = auth_info.name.split(" ")
            localStorage.setItem("access-token", auth_info.sub);
            localStorage.setItem("id", auth_info.sub);
            localStorage.setItem("enterpriseID", auth_info.asset_id);
            localStorage.setItem("Vang_asset", auth_info.asset_id);
            localStorage.setItem("Vang_rol", auth_info.rol);
            localStorage.setItem("enterpriseName", res.data.name);
            localStorage.setItem("username", auth_info.username);
            localStorage.setItem("name", nameSplited[0]);
            localStorage.setItem("lastname", nameSplited[1]);
            localStorage.setItem("role", auth_info.rol.toUpperCase());
            localStorage.setItem("role-name", auth_info.rol.toUpperCase());
            this.userCredentials.username = "";
            this.userCredentials.password = "";
            this.$bus.$emit('logged', 'User Logged');
            if (auth_info.rol.toUpperCase() == "IOT") {
              this.$router.replace({name: "dashIOT"})
            } else if (auth_info.rol.toUpperCase() == "IMG") { 
              this.$router.replace({name: "dashImgs"})
            } else {
              this.$router.replace({name: "mainModule"})
            }
        }); */
    },
  },
  created() {},
};
</script>
<style>
.all-screen {
  height: 100%;
}

.login-img {
  object-fit: cover;
}
</style>