import Vue from 'vue';
import Router from 'vue-router';
import auth from "./services/auth";

Vue.use(Router);

//Import components
import login from './components/login'
import dashboard from './components/dashboard';
import forms from './components/forms';
import registrations from './components/registrations';
import vehicleRegistrations from './components/vehicle-registrations';
import nominalView from './components/nominal/nominal-view';
import logs from './components/logs';
import dashIOT from './components/demo-iot/dashIOT';
import dashImgs from './components/demo-imgs/dashImgs';
import devices from './components/devices';
import dashCars from './components/dashboard-cars';
import mainModule from './components/main-module';
import generalDash from './components/general-dashboard';

import politics from './components/politics';

import permissions from './components/permissions';
import suborganizations from './components/visitors';
import visitorValidation from './components/visitor-validation';

import entryHistory from './components/entry-history';
import configuration from './components/configuration';
import vision from './components/vision/vision';
import settings from './components/vision/settings';
import registerUsers from './components/registration/register-users';
import registryPeople from './components/registration/registry-people';

import signinSchedule from './components/schedule-visitors/sign-in';
import schedule from './components/schedule-visitors/schedule';

import amplifyAuthTest from './components/amplifyAuthTest';

// * Vision ID
import visionid from './components/visionid/dashboard.vue';


Vue.component('login', login);
Vue.component('dashboard', dashboard);
Vue.component('forms', forms);
Vue.component('registrations', registrations);
Vue.component('logs', logs);
Vue.component('dashIOT', dashIOT);
Vue.component('dashImgs', dashImgs);
Vue.component('devices', devices);
Vue.component('dashCars', dashCars);
Vue.component('vehicleRegistrations', vehicleRegistrations);
Vue.component('mainModule', mainModule);
Vue.component('generalDash', generalDash);
Vue.component('entryHistory', generalDash);
Vue.component('configuration', configuration);
Vue.component('vision', vision);
Vue.component('settings', settings);
Vue.component('register', registerUsers);
Vue.component('registry-people', registryPeople);

Vue.component('signIn', signinSchedule);
Vue.component('schedule', schedule);

Vue.component('politics', politics);

Vue.component('permissions', permissions);
Vue.component('suborganizations', suborganizations);
Vue.component('visitorValidation', visitorValidation);

Vue.component('amplifyAuthTest', amplifyAuthTest)

// * Vision ID
Vue.component('visionid', visionid)


const appRoutes = [
  { 
    path: '', 
    redirect: { 
      name: "login" 
    } 
  },
  {
    path: '/authTest',
    name: "authTest",
    component: amplifyAuthTest
  },
  { 
    path: '/login', 
    name: "login", 
    component: login,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == true) {
        if (auth.getRole() == "IOT") {
          next("/dashIOT");
        } else if (auth.getRole() == "IMG") {
          next("/dashImgs");
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    } 
  },
  { 
    path: '/politicas', 
    name: "politics", 
    component: politics,
    /* beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == true) {
        if (auth.getRole() == "IOT") {
          next("/dashIOT");
        } else if (auth.getRole() == "IMG") {
          next("/dashImgs");
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    }  */
  },
  { 
    path: '/permissions', 
    name: "permissions", 
    component: permissions,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/suborganizations', 
    name: "suborganizations", 
    component: suborganizations,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/visitor-validation', 
    name: "visitorValidation", 
    component: visitorValidation,
    /* beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } */ 
  },
  { 
    path: '/register', 
    name: "register", 
    component: registerUsers,
    /* beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } */ 
  },
  { 
    path: '/registry-people', 
    name: "registry-people", 
    component: registryPeople,
    /* beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } */ 
  },
  { 
    path: '/oneid', 
    name: "signIn", 
    component: signinSchedule,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.schedule_loggedin() == true) {
        next("/oneid-main");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/oneid-main', 
    name: "schedule", 
    component: schedule,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.schedule_loggedin() == false) {
        next("/oneid");
      } else {
        next();
      }
    }
  },
  // ------------------VISION------------------
  { 
    path: '/vision', 
    name: "vision", 
    component: vision,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/settings', 
    name: "settings", 
    component: settings,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  // -------------------------------------
  { 
    path: '/main', 
    name: "mainModule", 
    component: mainModule,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/generalDash', 
    name: "generalDash", 
    component: generalDash,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashboard', 
    name: "dashboard", 
    component: dashboard,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashCars', 
    name: "dashCars", 
    component: dashCars,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/vehicleReg', 
    name: "vehicleReg", 
    component: vehicleRegistrations,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/forms', 
    name: "forms",
    component: forms,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/registrations', 
    name: "registrations", 
    component: registrations,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/nominal', 
    name: "nominal", 
    component: nominalView,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/devices', 
    name: "devices", 
    component: devices,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/entryHistory', 
    name: "entryHistory", 
    component: entryHistory,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/configuration', 
    name: "configuration", 
    component: configuration,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/logs', 
    name: "logs", 
    component: logs,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashIOT', 
    name: "dashIOT", 
    component: dashIOT,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashImgs', 
    name: "dashImgs", 
    component: dashImgs,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
   // ------------------VISION ID------------------
   { 
    path: '/visionid', 
    name: "visionid", 
    component: visionid,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/registrations-visionid', 
    name: "registrations_visionid", 
    component: registrations,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
]

const routes = [...appRoutes];

export default new Router({
  routes
});