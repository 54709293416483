<template>
  <v-container fluid>
    <div class="text-center" v-if="dialogVehicle == true">
      <v-dialog v-model="dialogVehicle" width="100%">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Administración Vehícular</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogVehicle = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <manage-vehicles
              :registryData="itemContentVehicle"
            ></manage-vehicles>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogRestaurant == true">
      <v-dialog v-model="dialogRestaurant" width="40%">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Administrar Almuerzos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogRestaurant = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <admin-lunch
              :registryData="personLunch"
            ></admin-lunch>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogCSV == true">
      <v-dialog v-model="dialogCSV" width="1000">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Carga masiva de información</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="lunchModule === true" outlined small @click="showLunchBox = !showLunchBox" class="mr-2">
              Restaurante
            </v-btn>
            <v-btn v-if="!showLunchBox" outlined small @click="generateExcelGuide">
              Descargar Guía
            </v-btn>
            <v-btn v-if="showLunchBox" outlined small @click="generateLunchExcelGuide">
              Descargar Guía
            </v-btn>
            <v-btn icon dark @click="closeBulkDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="!showLunchBox">
            <v-row>
              <v-col cols="12">
                  <v-file-input
                    accept=".xlsx"
                    label="Cargar archivo con información."
                    :disabled="loadingBulkUpdate"
                    @change="uploadFile"
                    dense
                    outlined
                    hide-details
                  ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 py-0 d-flex align-center">
                <label class="text-left font-weight-bold font-weight-black">
                  <v-icon>mdi-alert-circle</v-icon>
                  Tenga en cuenta que los valores seleccionados en los siguientes selectores se aplicaran a todas las personas de la lista proporcionada.
                  </label>
              </v-col>
              <!-- <v-col cols="12" md="6" v-if="organization.Campus && organization.Campus.length > 0">
                <v-select
                  v-model="bulkSuborganization"
                  :items="organization.Campus"
                  item-text="name"
                  item-value="uuid"
                  dense
                  label="Sede"
                  outlined
                  hide-details
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="bulkActive"
                  :items="activeInactive"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Estado Registros"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="groups && groups.length > 0">
                <v-select
                  v-model="bulkWorkingGroup"
                  :items="groups"
                  item-text="name"
                  item-value="uuid"
                  dense
                  label="Grupos nominales"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-select
                  v-model="bulkTypePerson"
                  :items="personTypes"
                  dense
                  label="Tipo empleado"
                  outlined
                  hide-details
                ></v-select>
              </v-col> -->
              <v-col cols="12">
                <v-btn 
                  outlined 
                  block 
                  small
                  color="primary" 
                  :disabled="loadingBulkUpdate || bulkData.length == 0"
                  :loading="loadingBulkUpdate" 
                  @click="bulkUpdate(bulkSuborganization, bulkWorkingGroup, bulkTypePerson, bulkActive)"
                >
                  Enviar Información
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-3" v-if="bulkErrors.length > 0">
                <label class="text-h6">Advertencias</label>
                <div max-height="100px" class="bulkErrorBox">
                  <div v-for="(item, i) in bulkErrors" :key="i">
                    <label class="red--text">{{ item }}</label>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  dense
                  :headers="csvHeaders"
                  :items="bulkData"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options': [15],
                  }"
                >
                <template v-slot:item="{ item }">
                  <tr class="mx-0 px-0">
                    <td class="text-body-2 text-center">
                      <span v-if="item.document_number && item.document_number !== ''" :class="item.document_number && item.document_number !== '' ? 'primary--text' : ''">{{
                        item.document_number && item.document_number !== '' ? item.document_number : '---'
                      }}</span>
                      <span v-else :class="item.CEDULA && item.CEDULA !== '' ? 'primary--text' : ''">{{
                        item.CEDULA && item.CEDULA !== '' ? item.CEDULA : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.email && item.email !== ''" :class="item.email && item.email !== '' ? 'primary--text' : ''">{{
                        item.email && item.email !== '' ? item.email : '---'
                      }}</span>
                      <span v-else :class="item.EMAIL && item.EMAIL !== '' ? 'primary--text' : ''">{{
                        item.EMAIL && item.EMAIL !== '' ? item.EMAIL : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.phone && item.phone !== ''" :class="item.phone && item.phone !== '' ? 'primary--text' : ''">{{
                        item.phone && item.phone !== '' ? item.phone : '---'
                      }}</span>
                      <span v-else :class="item.CELULAR && item.CELULAR !== '' ? 'primary--text' : ''">{{
                        item.CELULAR && item.CELULAR !== '' ? item.CELULAR : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.address && item.address !== ''" :class="item.address && item.address !== '' ? 'primary--text' : ''">{{ item.address && item.address !== '' ? item.address : '---' }}</span>
                      <span v-else :class="item.DIRECCIÓN && item.DIRECCIÓN !== '' ? 'primary--text' : ''">{{ item.DIRECCIÓN && item.DIRECCIÓN !== '' ? item.DIRECCIÓN : '---' }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.position && item.position !== ''" :class="item.position && item.position !== '' ? 'primary--text' : ''">{{
                        item.position && item.position !== '' ? item.position : '---'
                      }}</span>
                      <span v-else :class="item.CARGO && item.CARGO !== '' ? 'primary--text' : ''">{{
                        item.CARGO && item.CARGO !== '' ? item.CARGO : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.costCenter && item.costCenter !== ''" :class="item.costCenter && item.costCenter !== '' ? 'primary--text' : ''">{{
                        item.costCenter && item.costCenter !== '' ? item.costCenter : '---'
                      }}</span>
                      <span v-else :class="item.CENTRO_COSTOS && item.CENTRO_COSTOS !== '' ? 'primary--text' : ''">{{
                        item.CENTRO_COSTOS && item.CENTRO_COSTOS !== '' ? item.CENTRO_COSTOS : '---'
                      }}</span>
                    </td>
                    <!-- <td class="text-body-2 text-center">//TODO: Arreglar forma de subida de fecha desde excel
                      <span :class="item.disengagement_date && item.disengagement_date !== '' ? 'primary--text' : ''">{{
                        item.disengagement_date && item.disengagement_date !== '' ? item.disengagement_date : '---'
                      }}</span>
                    </td> -->
                  </tr>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="showLunchBox">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  accept=".xlsx"
                  label="Cargar archivo con información de almuerzos."
                  :disabled="loadingBulkUpdate"
                  @change="uploadLunchFile"
                  dense
                  outlined
                  hide-details
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 py-0 d-flex align-center">
                <label class="text-left font-weight-bold font-weight-black">
                  <v-icon>mdi-alert-circle</v-icon>
                  Por favor verifique la lista para resaurantes antes de continuar con el envio de datos.
                </label>
              </v-col>
              <v-col cols="12">
                <v-btn 
                  outlined 
                  block 
                  small
                  color="primary" 
                  :disabled="loadingBulkUpdate || bulkLunchData.length == 0"
                  :loading="loadingBulkUpdate" 
                  @click="bulkLunchUpdate"
                >
                  Enviar Información
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-3" v-if="bulkErrors.length > 0">
                <label class="text-h6">Advertencias</label>
                <div max-height="100px" class="bulkErrorBox">
                  <div v-for="(item, i) in bulkErrors" :key="i">
                    <label class="red--text">{{ item }}</label>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  dense
                  :headers="lunchHeaders"
                  :items="bulkLunchData"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options': [15],
                  }"
                >
                <template v-slot:item="{ item }">
                  <tr class="mx-0 px-0">
                    <td class="text-body-2 text-center">
                      <span v-if="item.document_number && item.document_number !== ''" :class="item.document_number && item.document_number !== '' ? 'primary--text' : ''">{{
                        item.document_number && item.document_number !== '' ? item.document_number : '---'
                      }}</span>
                      <span v-else :class="item.CEDULA && item.CEDULA !== '' ? 'primary--text' : ''">{{
                        item.CEDULA && item.CEDULA !== '' ? item.CEDULA : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.lunch_number && item.lunch_number !== ''" :class="item.lunch_number && item.lunch_number !== '' ? 'primary--text' : ''">{{
                        item.lunch_number && item.lunch_number !== '' ? item.lunch_number : '---'
                      }}</span>
                      <span v-else :class="item.ALMUERZOS && item.ALMUERZOS !== '' ? 'primary--text' : ''">{{
                        item.ALMUERZOS && item.ALMUERZOS !== '' ? item.ALMUERZOS : '---'
                      }}</span>
                    </td>
                  </tr>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogQR == true">
      <v-dialog
        v-model="dialogQR"
        width="1000"
        content-class="modalVs"
      >
        <user-registry></user-registry>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogDelete == true">
      <v-dialog v-model="dialogDelete" persistent width="500">
        <v-card>
          <v-card-title class="d-flex justify-center">
            Desactivar Registro
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <p class="my-0 py-0">
                  <small
                    >Está seguro que desea desactivar el registro con N° de
                    documento: </small
                  ><strong>{{ itemContent.document_number }}</strong>
                </p>
                <p class="my-0 py-0">
                  <small
                    >Por favor ingrese el número para validar la
                    desactivación.</small
                  >
                </p>
              </v-col>
              <v-col cols="12" class="my0 py-0">
                <v-text-field
                  v-model="cc_validation"
                  type="number"
                  label="Escriba el documento"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  color="error"
                  block
                  :loading="loadingReg"
                  :disabled="
                    cc_validation && cc_validation.length > 3 ? false : true
                  "
                  @click="deleteRegistry(itemContent, cc_validation)"
                >
                  DESACTIVAR REGISTRO
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  block
                  :disabled="loadingReg"
                  @click="
                    dialogDelete = false;
                    cc_validation = null;
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogBL == true">
      <v-dialog v-model="dialogBL" persistent width="500">
        <div class="text-center" v-if="dialogBLDoubleValidation == true">
          <v-dialog v-model="dialogBLDoubleValidation" persistent width="400">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="text-center">Está seguro que desea agregar esta persona a una lista negra?</p>
                  </v-col>
                </v-row>
                <v-row class="my0 py-0">
                  <v-col cols="12" md="6" sm="6">
                    <v-btn
                      color="error"
                      block
                      small
                      :loading="loadingReg"
                      @click="assignBL(itemContent)"
                    >
                      SI, PROCEDER
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-btn
                      block
                      small
                      @click="dialogBLDoubleValidation = !dialogBLDoubleValidation"
                    >
                      CANCELAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>

        <v-card>
          <v-card-title class="d-flex justify-center">
            Asignar Lista Negra
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <p class="my-0 py-0">
                  <small
                    >Está seguro que desea asignar a una lista negra el registro con N° de
                    documento: </small
                  ><strong>{{ itemContent.document_number }}</strong>
                </p>
                <p class="my-0 py-0">
                  <small
                    >Por favor seleccione la lista negra que desea asignar.</small
                  >
                </p>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <v-select
                  v-model="itemContent.company_person.pin"
                  :items="permissionGroupsBL"
                  item-text="name"
                  item-value="uuid"
                  return-object
                  dense
                  label="Grupo de Permisos / Rol"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  color="secondary"
                  block
                  :disabled="itemContent.company_person.pin.uuid != null ? false : true"
                  @click="dialogBLDoubleValidation = !dialogBLDoubleValidation"
                >
                  ASIGNAR LISTA
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  block
                  color="error"
                  @click="
                    dialogBL = false ; getOneRegistry(itemContent, 'search');
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="changeImageDialog == true">
        <v-dialog v-model="changeImageDialog" width="500">
          <v-card>
            <v-card-text class="pb-0">
              <v-row class="text-center">
                <v-col cols="12" class="d-flex justify-center">
                  <v-expansion-panels accordion flat>
                    <v-expansion-panel style="background-color: #3C465D;">
                      <v-expansion-panel-header>Información a tener en cuenta</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <label class="text-caption mb-3">
                          -Tenga en cuenta que el cambio de imagen de perfil afectara de manera directa su reconocimiento con los dispositivos de acceso,
                          por lo anterior se le recomienda que no lleve alguno de los siguientes elementos: lentes, sombrero, gorra o mascarilla.
                        </label>
                        <label class="text-caption">
                          -Tambien se recomienda que el lente de su dispositivo este limpio y tenga una fuente de iluminación buena para que su foto quede con la mejor calidad posible.
                        </label>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pb-0">
                  <p class="text-h5">Actualizar Imagen de Perfil</p>
                </v-col>
                <div v-if="selfieMode1 && !selfieMode2" style="width: 100%">
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <take-photo :typeUse="'selfie'" :imageName="'new_selfie_' + newImgUUID" v-on:photo-taken-selfie="validateChangeImg"></take-photo>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0">
                    <v-btn
                      x-small
                      color="grey darken-3"
                      @click="selfieMode1 = !selfieMode1 ; selfieMode2 = !selfieMode2"
                    >
                      Cambiar Modo
                    </v-btn>
                  </v-col>
                  <v-divider></v-divider>
                </div>

                <div v-if="selfieMode2 && !selfieMode1" style="width: 100%">
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <v-icon v-if="newSelfieImg == null" style="font-size: 120px;" x-large color="grey" dark> mdi-account-box</v-icon>
                    <v-img
                      v-if="newSelfieImg != null"
                      :src="newSelfieImg"
                      width="250px"
                      height="200px"
                      class="white--text align-center"
                      
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="success"
                      class="white--text"
                      small
                      @click="clickNewSelfieInput"
                    >
                      SUBIR SELFIE
                    </v-btn>
                    <input
                      type="file"
                      @change="readNewSelfieFile"
                      style="display: none"
                      ref="newSelfieInput"
                      accept="image/*"
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0">
                    <v-btn
                      x-small
                      color="grey darken-3"
                      @click="selfieMode1 = !selfieMode1 ; selfieMode2 = !selfieMode2"
                    >
                      Cambiar Modo
                    </v-btn>
                  </v-col>
                  <v-divider></v-divider>
                </div>

                <v-col cols="12" class="text-center">
                  <v-btn
                    rounded
                    color="primary"
                    small
                    class="px-4 mx-2 primaryBtn"
                    @click="changeProfileImg"
                    :loading="loadingChangeProfileImgBtn"
                    :disabled="!validFaceAnalysis"
                  >
                    Actualizar Imagen de Perfil
                  </v-btn> 
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="white--text text-h5">Gestión de Registros</p>
      </v-col>
    </v-row>
    <v-row v-if="roleChecker.iHaveGrants(userRole, 'REG-CREATE')" class="text-center">
      <v-col cols="12" md="6">
        <v-btn
          color="primary"
          rounded
          block
          large
          class="primaryBtn"
          @click="dialogQR = true"
        >
          <i class="fal fa-user-plus mx-2"></i> Gestionar Registro 
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          color="success"
          block
          large
          rounded
          @click="dialogCSV = true"
          :disabled="!itemsList || itemsList.length == 0"
        >
          <i class="fal fa-file-upload mx-2"></i> Carga masiva de información
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px;">
          <v-row class="text-center">
            <v-col
              cols="12"
              md="7"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar registros"
                single-line
                dense
                outlined
                dark
                color="secondary"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="registryFilter"
                :items="registerStatusFilter"
                item-text="name"
                item-value="uuid"
                return-object
                dense
                label="Filtro Estados"
                outlined
                dark
                color="secondary"
                hide-details
                @change="getRegistry(registryFilter)"
              ></v-select>
            </v-col>
            <v-col
              v-if="roleChecker.iHaveGrants(userRole, 'REG-UPDATE')"
              class="d-flex justify-center align-center"
              cols="12"
              md="2"
            >
              <v-btn
                color="success"
                block
                :loading="loadingReport"
                :disabled="
                  itemsList.length > 0 || loadingReport === false ? false : true
                "
                @click="generateRegistryReport(itemsList)"
              >
                <v-icon dark> mdi-microsoft-excel </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="itemsList"
                  :search="search"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón registros por su dato de busqueda (${search})`"
                  no-data-text="No hay registros que mostrar"
                  dense
                  fixed-header
                >
                  <template v-slot:[`item.state`]="{ item }">
                    <v-avatar
                      v-if="item.company_person.registry_status == 'active'"
                      color="success"
                      size="15"
                    >
                    </v-avatar>
                    <v-avatar
                      v-if="item.company_person.registry_status == 'restricted'"
                      color="red"
                      size="15"
                    >
                    </v-avatar>
                    <v-avatar
                      v-if="item.company_person.registry_status == 'unassigned'"
                      color="grey"
                      size="15"
                    >
                    </v-avatar>
                    <v-avatar
                      v-if="item.company_person.registry_status == 'black_list'"
                      color="black"
                      size="15"
                    >
                    </v-avatar>
                  </template>
                  <template v-slot:[`item.company_person.created_at`]="{ item }">
                    {{ item.company_person.created_at.substring(0, 10) }}
                  </template>
                  <template v-slot:[`item.HoraIngreso`]="{ item }">
                    {{ item.company_person.created_at.substring(11, 16) }}
                  </template>
                  <template v-slot:[`item.options`]="{ item }">
                    <v-btn
                      v-if="lunchModule === true"
                      icon
                      :class="item.lunch_number != null && item.lunch_number > 0 ? 'success' : 'secondary'"
                      @click="adminLunch(item)"
                    >
                      <i class="fal fa-utensils" color="success"></i>
                    </v-btn>
                    <span class="mx-1"></span>
                    <v-btn
                      v-if="modules == 2 || modules == 3 || modules == 5 || modules == 6"
                      icon
                      :class="item.vehicles.length > 0 ? 'success' : 'secondary'"
                      @click="adminVehicles(item)"
                    >
                      <i class="fal fa-car" color="success"></i>
                    </v-btn>
                    <span class="mx-1"></span>
                    <v-btn
                      icon
                      class="primary"
                      @click="
                        getOneRegistry(item, 'search');
                        editMode = false;
                      "
                    >
                      <i class="fal fa-eye"></i>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center">
        <v-card elevation="0" v-if="loadingProfile === false && !itemContent" style="border-radius: 10px;background-color: #212734 !important;">
          <v-row>
            <v-col cols="12" class="text-center">
              <p class="grey--text font-weight-bold text-h5">
                Seleccione un usuario para consultar su información
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card style="background-color: #212734 !important;" elevation="0" v-if="loadingProfile === true && !itemContent">
          <v-row v-if="loadingProfile === true && !itemContent">
            <v-col cols="12" class="text-center">
              <p class="font-weight-bold white--text text-h6">
                Cargando Perfil
                <v-progress-linear
                  color="#4ce6ad"
                  buffer-value="0"
                  stream
                ></v-progress-linear>
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" v-if="itemContent" style="border-radius: 10px;background-color: #212734 !important;">
          <v-row class="mt-15">
            <v-col
              cols="12"
              class="d-flex justify-center align-center ma-0 pa-0"
            >
              <v-img
                :src="PSRegistryImg"
                class="white--text rounded-edges"
                max-height="300px"
                max-width="400px"
                contain
                @error="imgErrorHandler"
              >
                <div v-if="roleChecker.iHaveGrants(userRole, 'REG-UPDATE')" class="d-flex justify-center">
                  <v-btn
                    color="grey darken-3"
                    x-small
                    style="position: absolute; top: 85%;"
                    @click="startProfileImgUpdate"
                  >
                    MODIFICAR IMAGEN
                  </v-btn>                  
                </div>
              </v-img>
            </v-col>
            <v-row class="d-flex justify-center align-center mt-10 ml-10 mr-10">
              <v-col cols="12" :md="userOrgName == 'solla' ? '3' : '12'" class="ma-0 pa-0 d-flex justify-center">
                <!-- <p class="text-center">{{ itemContent.company_person.agree_terms_id == true ? "ACTIVO" : "INACTIVO" }}</p> -->
                <v-chip
                  v-if="selectedPG == null"
                  class="ma-2"
                  small
                  color="grey"
                  text-color="white"
                >
                  <v-avatar
                    left
                    style="height: 20px !important; width: 20px !important;"
                    class="grey darken-4"
                  >
                  </v-avatar>
                  SIN ASIGNAR
                </v-chip>
                <v-chip 
                  v-if="selectedPG !== null && (!selectedPG.type_group || (selectedPG.type_group && selectedPG.type_group !== 'black_list')) && itemContent.company_person.agree_terms_id == true"
                  class="ma-2"
                  small
                  color="green"
                  text-color="white"
                >
                  <v-avatar
                    left
                    style="height: 20px !important; width: 20px !important;"
                    class="green darken-4"
                  >
                  </v-avatar>
                  HABILITADO
                </v-chip>
                <v-chip 
                  v-if="selectedPG !== null && (!selectedPG.type_group || (selectedPG.type_group && selectedPG.type_group !== 'black_list')) && itemContent.company_person.agree_terms_id == false"
                  class="ma-2"
                  small
                  color="error"
                  text-color="white"
                >
                  <v-avatar
                    left
                    style="height: 20px !important; width: 20px !important;"
                    class="error darken-4"
                  >
                  </v-avatar>
                  RESTRINGIDO
                </v-chip>
                <v-chip
                  v-if="selectedPG !== null && selectedPG.type_group && selectedPG.type_group == 'black_list'"
                  class="ma-2"
                  small
                  color="black"
                  text-color="white"
                >
                  <v-avatar
                    left
                    style="height: 17px !important; width: 17px !important;"
                    class="grey darken-4"
                  >
                  </v-avatar>
                  LN - {{selectedPG.name}}
                </v-chip>
              </v-col>
              <v-col v-if="userOrgName == 'solla'" cols="12" md="6" class="ma-0 pa-0 d-flex justify-center">
                <p class="text-body-1">Última Encuesta: 
                  <strong v-if="last_survey && last_survey.created_date" :class="glbs.validateSurveyStatus(last_survey.created_date) ? 'success--text' : 'red--text'">{{ last_survey.created_date.substring(0, 10) + " - " + last_survey.created_date.substring(11, 16) }}</strong>
                  <strong v-else>N/R</strong>
                </p>
              </v-col>
              <v-col cols="12" md="3" sm="3" class="ma-0 pa-0 d-flex justify-center">
                <v-chip
                  v-if="itemContent.company_person.state == 'IN'"
                  class="ma-2"
                  color="secondary"
                  small
                  label
                  outlined
                >
                  <strong>DENTRO</strong>
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2"
                  color="grey"
                  small
                  label
                  outlined
                >
                  <strong>FUERA</strong>
                </v-chip>
              </v-col>
              <v-col cols="11" md="5" class="grey--text text-center">
                <v-select
                  v-model="itemContent.company_person.pin"
                  :items="permissionGroupsList"
                  :disabled="!editMode"
                  item-text="name"
                  item-value="uuid"
                  return-object
                  dense
                  label="Grupo de Permisos / Rol"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="1" class="text-center ma-0 pa-0">
                <v-btn
                  fab
                  small
                  color="black ligthen-3"
                  :disabled="editMode"
                  @click="dialogBL = true; activateEditMode(itemContent, 'bl')"
                >
                  <v-icon>mdi-close-octagon-outline</v-icon>
                </v-btn>
              </v-col>
              <!-- // TODO: TERMINAR LOGICA NUEVA DE ACTUALIZACION DE CAMPUS -->
              <!-- <v-col cols="12" md="3" sm="6" class="grey--text text-center">
                <small>
                  Sub Org:
                  <strong class="blue--text">NO REGISTRA</strong>
                </small>
                <small
                  v-if="
                    !editMode
                  "
                >
                  Sub Org:
                  <strong class="blue--text">{{
                    itemContent.company_person.suborgs_person.length > 0 && itemContent.company_person.suborgs_person[0].sub_org.name
                      ? itemContent.company_person.suborgs_person[0].sub_org.name
                      : "NO REGISTRA"
                  }}</strong>
                </small>
                <v-select
                  v-if="
                    editMode
                  "
                  v-model="itemContent.company_person.suborgs_person[0].sub_org"
                  :items="
                    itemContent.company_person.Campus_company_person[0].campus.subOrganization
                  "
                  item-text="name"
                  return-object
                  dense
                  label="Sub Organizacion"
                ></v-select>
              </v-col> -->
              <!-- ---------------------------- -->
              <!-- <v-col cols="12" md="3" sm="6" class="grey--text text-center">
                <small v-if="!editMode">
                  T. Usuario:
                  <strong class="white--text">{{ itemContent.company_person.type_person }}</strong>
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.company_person.type_person"
                  :items="personTypes"
                  dense
                  label="T. Usuario"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col> -->
              <!-- ---------------------------- -->
              <v-col cols="12" :md="userOrgName == 'solla' ? '6' : '3'" class="grey--text text-center">
                <v-select
                  v-model="itemContent.company_person.suborgs_person[0].sub_org"
                  :items="suborgsList"
                  :disabled="!editMode"
                  item-text="name"
                  item-value="uuid"
                  return-object
                  dense
                  label="Suborganización"
                  outlined
                  dark
                  hide-details="auto"
                  color="secondary"
                ></v-select>
                <!-- ||||||||||||||||||||||||||||||||||| -->
                <!-- <div
                  class="px-3"
                  :class="itemContent.company_person.Campus_company_person.length > 4 ? 'group-sedes' : ''"
                >
                  <label><small>Lugares Permitidos: </small></label>
                  <v-chip-group
                    column
                  >
                    <v-chip
                      v-for="(sede, i) in itemContent.company_person.Campus_company_person" :key="i"
                      small
                      class="sede-chip"
                      primary
                      disabled
                    >
                      {{ sede.name }}
                    </v-chip>
                  </v-chip-group>
                </div> -->
                <!-- <v-select
                  v-model="itemContent.company_person.Campus_company_person"
                  v-if="editMode"
                  :items="organization.Campus"
                  item-text="name"
                  return-object
                  chips
                  multiple
                  label="Sede"
                  outlined
                  dark
                  hide-details="auto"
                  color="secondary"
                ></v-select> -->
              </v-col>
              <!-- // TODO: Uncomment if everything is alrigth 
              <v-col cols="12" md="3" sm="6" class="grey--text text-center">
                <small v-if="!editMode">
                  Nvl. Acceso:
                  <v-chip
                    class="ma-2"
                    color="primary"
                    text-color="white"
                    v-if="itemContent.IdAcceso && itemContent.IdAcceso != ''"
                  >
                    <v-avatar left class="primary darken-4">
                      {{ itemContent.IdAcceso }}
                    </v-avatar>
                    Nivel
                  </v-chip>
                  <strong
                    class="white--text"
                    v-if="!itemContent.IdAcceso || itemContent.IdAcceso == ''"
                    >NO REGISTRA</strong
                  >
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.IdAcceso"
                  :items="accessLevels"
                  disabled
                  dense
                  label="Nvl. Acceso"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col> -->
            </v-row>
            <v-row class="d-flex justify-center align-center ml-10 mr-10">
              <v-col cols="12" md="3" class="grey--text text-center"
                >
                <!-- <v-text-field
                  v-if="editMode"
                  label="Primer Nombre"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.first_name"
                ></v-text-field> -->
                <small>
                  1er Nombre:
                  <strong class="white--text">{{
                    itemContent.first_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                >
                <!-- <v-text-field
                  v-if="editMode"
                  label="Segundo Nombre"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.second_name"
                ></v-text-field> -->
                <small>
                  2do Nombre:
                  <strong class="white--text">{{
                    !itemContent.second_name ? "- - -" : itemContent.second_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                >
                <!-- <v-text-field
                  v-if="editMode"
                  label="Primer Apellido"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.first_last_name"
                ></v-text-field> -->
                <small>
                  1r Apellido:
                  <strong class="white--text">{{
                    !itemContent.first_last_name ? "- - -" : itemContent.first_last_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                >
                <!-- <v-text-field
                  v-if="editMode"
                  label="Segundo Apellido"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.second_last_name"
                ></v-text-field> -->
                <small>
                  2do Apellido:
                  <strong class="white--text">{{
                    !itemContent.second_last_name
                      ? "- - -"
                      : itemContent.second_last_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                >
                <!-- <v-text-field
                  v-if="editMode"
                  label="Documento"
                  outlined
                  dark
                  color="secondary"
                  dense
                  required
                  v-model="itemContent.document_number"
                ></v-text-field> -->
                <small>
                  CC:
                  <strong class="white--text">{{
                    itemContent.document_number
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  Edad:
                  <strong class="white--text" v-if="itemContent.birthdate">{{
                    gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) > 5 ? gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) : '---'
                  }}</strong>
                  <strong class="white--text" v-else>- - -</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small v-if="!editMode">
                  Fecha Nacimiento:
                  <strong class="white--text" v-if="itemContent.birthdate">{{
                    gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) > 5 ? itemContent.birthdate.substring(0, 10) : '---'
                  }}</strong>
                  <strong class="white--text" v-else>N/R</strong>
                </small>
                <v-menu
                  v-if="editMode"
                  v-model="datepickerBirthday"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="itemContent.birthdate"
                      label="Fecha Nacimiento"
                      readonly
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="itemContent.birthdate"
                    @input="datepickerBirthday = false"
                    locale="es-MX"
                  ></v-date-picker>
                </v-menu>
              </v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small v-if="!editMode">
                  RH:
                  <strong class="white--text">{{ !itemContent.rh ? "N/R" : itemContent.rh }}</strong>
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.rh"
                  :items="RHTypes"
                  dense
                  label="RH"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small v-if="!editMode">
                  Genero:
                  <strong class="white--text" v-if="itemContent.gender">{{
                    itemContent.gender == "M" ? "MASCULINO" : "FEMENINO"
                  }}</strong>
                  <strong class="white--text" v-else>N/R</strong>
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.gender"
                  :items="genderTypes"
                  dense
                  label="Genero"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-select>
              </v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <small>
                  Fecha Registro:
                  <strong class="white--text">{{
                    itemContent.company_person.created_at.substring(0, 10)
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Email"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.email"
                ></v-text-field>
                <!-- <small v-if="!editMode">
                  Email:
                  <strong class="white--text">{{
                    !itemContent.emails || itemContent.emails.length == 0 || (itemContent.emails.length == 1 && itemContent.emails[0].email == '')
                      ? "NO REGISTRA"
                      : itemContent.emails[itemContent.emails.length - 1].email
                  }}</strong> -->
                  <small v-if="!editMode">
                  Email:
                  <strong class="white--text">{{
                    !itemContent.email 
                      ? "NO REGISTRA"
                      : itemContent.email
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Celular"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.phone"
                ></v-text-field>
                <!-- <small v-if="!editMode">
                  Celular:
                  <strong class="white--text">{{
                    !itemContent.phones || itemContent.phones.length == 0 || (itemContent.phones.length == 1 && itemContent.phones[0].phone == '')
                      ? "NO REGISTRA"
                      : itemContent.phones[itemContent.phones.length - 1].phone
                  }}</strong>
                </small></v-col -->
                <small v-if="!editMode">
                  Celular:
                  <strong class="white--text">{{
                    !itemContent.phone
                      ? "NO REGISTRA"
                      : itemContent.phone
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Dirección"
                  outlined
                  dark
                  color="secondary"
                  dense
                  hide-details="auto"
                  v-model="itemContent.addresses[itemContent.addresses.length - 1].Address"
                ></v-text-field>
                <small v-if="!editMode" :title="itemContent.addresses[itemContent.addresses.length - 1].created_at">
                  Dirección:
                  <strong class="white--text">{{
                    !itemContent.addresses || itemContent.addresses.length == 0 || (itemContent.addresses.length == 1 && itemContent.addresses[0].Address == '')
                      ? "NO REGISTRA"
                      : itemContent.addresses[itemContent.addresses.length - 1].Address
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center" v-if="modules == 2 || modules == 3 || modules == 5 || modules == 6">
                <v-chip
                  class="ma-2"
                  :color="itemContent.vehicles.length == 0 ? 'primary' : 'success'"
                  text-color="white"
                  @click="adminVehicles(itemContent)"
                >
                  Vehículos
                  <v-avatar right :class="itemContent.vehicles.length == 0 ? 'primary darken-4' : 'success darken-4'">
                    {{ itemContent.vehicles.length }}
                  </v-avatar>
                </v-chip>
              </v-col>
              <v-col v-if="userOrgName !== 'solla' || (userOrgName == 'solla' && userRole !== 'VISUALIZATION')" cols="12">
                <v-divider></v-divider>
              </v-col>
              </v-row>
              <!-- --------------------------- CAMPOS EXTERNOS -------------------------------- -->
              <v-row v-if="userOrgName == 'solla' && userRole !== 'VISUALIZATION'" class="d-flex justify-center align-center ml-10 mr-10">
                <v-col cols="12" md="3" class="grey--text text-center">
                  <small>
                    Empresa:
                    <strong class="white--text">{{
                      externalData && externalData.company
                        ? externalData.company
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="3" class="grey--text text-center">
                  <small>
                    Regional:
                    <strong class="white--text">{{
                      externalData && externalData.regional
                        ? externalData.regional
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="3" class="grey--text text-center">
                  <small>
                    F. Ingreso:
                    <strong class="white--text">{{
                      externalData && externalData.fechaIngreso
                        ? externalData.fechaIngreso
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small>
                    Cargo:
                    <strong class="white--text">{{
                      externalData && externalData.jobTitle
                        ? externalData.jobTitle
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small>
                    UEN:
                    <strong class="white--text">{{
                      externalData && externalData.uen
                        ? externalData.uen
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small>
                    Proceso:
                    <strong class="white--text">{{
                      externalData && externalData.process
                        ? externalData.process
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small>
                    Area:
                    <strong class="white--text">{{
                      externalData && externalData.area
                        ? externalData.area
                        : "N/R"
                    }}</strong>
                  </small>
                </v-col>
              </v-row>
              <v-row v-if="userOrgName == 'luxe'" class="d-flex justify-center align-center ml-10 mr-10">
                <v-col cols="12" class="py-0 my-0">
                  <p class="text-center text-subtitle-1 py-0 my-0">Información Estadía</p>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <v-select
                    v-if="editMode"
                    v-model="employeeData.guestType"
                    :items="guestTypes"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Tipo de Usuario"
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                    @change="employeeData.guestType == 'visitor' || employeeData.guestType == 'pool_visitor' ? employeeData.guestRoom = '' : ''"
                  ></v-select>
                  <small v-if="!editMode">
                    Tipo de Usuario:
                    <strong class="white--text">{{
                      !employeeData.guestType
                        ? "NO ASIGNADO"
                        : (employeeData.guestType == 'guest' ? 'HUESPED' : (employeeData.guestType == 'visitor' ? 'VISITANTE' : 'VISITANTE DIA SOL'))
                    }}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="6" class="grey--text text-center">
                  <v-text-field
                    v-if="editMode"
                    outlined
                    dark
                    color="secondary"
                    dense
                    hide-details="auto"
                    label="Habitación o Cabaña"
                    v-model="employeeData.guestRoom"
                    :disabled="employeeData.guestType == 'visitor' || employeeData.guestType == 'pool_visitor' ? true : false"
                  ></v-text-field>
                  <small v-if="!editMode">
                    N° Habitación o Cabaña:
                    <strong class="white--text">{{
                      !employeeData.guestRoom
                        ? "NO ASIGNADO"
                        : employeeData.guestRoom
                    }}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small v-if="!editMode">
                    Fecha Llegada:
                    <strong class="white--text">{{
                      employeeData.guestCheckinDate
                        ? employeeData.guestCheckinDate
                        : "NO ASIGNADA"
                    }}</strong>
                  </small>
                  <v-menu
                    v-model="datepickerCheckin"
                    v-if="editMode"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="employeeData.guestCheckinDate"
                        label="Fecha Llegada"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="employeeData.guestCheckinDate"
                      @input="datepickerCheckin = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="grey--text text-center">
                  <small v-if="!editMode">
                    Fecha Salida:
                    <strong class="white--text">{{
                      employeeData.guestCheckoutDate
                        ? employeeData.guestCheckoutDate
                        : "NO ASIGNADA"
                    }}</strong>
                  </small>
                  <v-menu
                    v-model="datepickerCheckout"
                    v-if="editMode"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="employeeData.guestCheckoutDate"
                        label="Fecha Salida"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="employeeData.guestCheckoutDate"
                      @input="datepickerCheckout = false"
                      :min="
                        employeeData.guestCheckinDate
                      "
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row v-if="userOrgName !== 'solla'" class="d-flex justify-center align-center ml-10 mr-10">
                <v-col cols="12" md="4" class="grey--text text-center">
                  <small>
                    Estado Empleado:
                    <v-chip
                      v-if="
                        statusEmployee(
                          itemContent.company_person.created_at.substring(0, 10),
                          itemContent.company_person.disengagement_date ? itemContent.company_person.disengagement_date.substring(0, 10) : ''
                        )
                      "
                      small
                      color="#34d399"
                      text-color="white"
                      >ACTIVO</v-chip
                    >
                    <v-chip
                      v-if="
                        !statusEmployee(
                          itemContent.company_person.created_at.substring(0, 10),
                          itemContent.company_person.disengagement_date ? itemContent.company_person.disengagement_date.substring(0, 10) : ''
                        )
                      "
                      small
                      color="red"
                      text-color="white"
                      >INACTIVO</v-chip
                    >
                  </small></v-col
                >
                <v-col cols="12" md="4" class="grey--text text-center">
                  <small v-if="!editMode">
                    Fecha Ingreso:
                    <strong class="white--text">{{
                      employeeData.ingresoDate
                        ? employeeData.ingresoDate
                        : "NO ASIGNADA"
                    }}</strong>
                  </small>
                  <v-menu
                    v-model="datepickerIngreso"
                    v-if="editMode"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="employeeData.ingresoDate"
                        label="Fecha Ingreso"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="employeeData.ingresoDate"
                      @input="datepickerIngreso = false"
                      :min="
                        itemContent.company_person.created_at.substring(0, 10)
                      "
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="grey--text text-center">
                  <small v-if="!editMode">
                    Fecha Retiro:
                    <strong class="white--text">{{
                      itemContent.company_person.disengagement_date
                        ? itemContent.company_person.disengagement_date.substring(0, 10)
                        : "NO ASIGNADA"
                    }}</strong>
                  </small>
                  <v-menu
                    v-model="datepickerRetiro"
                    v-if="editMode"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="itemContent.company_person.disengagement_date"
                        label="Fecha Retiro"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="itemContent.company_person.disengagement_date"
                      @input="datepickerRetiro = false"
                      :min="itemContent.company_person.created_at.substring(0, 10)"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="grey--text text-center">
                  <v-text-field
                    v-if="editMode"
                    outlined
                    dark
                    color="secondary"
                    dense
                    hide-details="auto"
                    label="Cargo"
                    v-model="itemContent.company_person.Positions[itemContent.company_person.Positions.length - 1].position"
                  ></v-text-field>
                  <small v-if="!editMode">
                    Cargo:
                    <strong class="white--text">{{
                      !itemContent.company_person.Positions || itemContent.company_person.Positions.length == 0 || (itemContent.company_person.Positions.length == 1 && itemContent.company_person.Positions[0].position == '')
                        ? "NO ASIGNADO"
                        : itemContent.company_person.Positions[itemContent.company_person.Positions.length - 1].position
                    }}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="4" class="grey--text text-center">
                  <v-text-field
                    v-if="editMode"
                    outlined
                    dark
                    color="secondary"
                    dense
                    hide-details="auto"
                    label="C. Operaciones"
                    v-model="employeeData.operationCenter"
                  ></v-text-field>
                  <small v-if="!editMode">
                    C. Operaciones:
                    <strong class="white--text">{{
                      !employeeData.operationCenter ||
                      employeeData.operationCenter == ""
                        ? "NO ASIGNADO"
                        : employeeData.operationCenter
                    }}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="4" class="grey--text text-center">
                  <v-text-field
                    v-if="editMode"
                    outlined
                    dark
                    color="secondary"
                    dense
                    hide-details="auto"
                    label="C. Operaciones Desc."
                    v-model="employeeData.operationCenterDesc"
                  ></v-text-field>
                  <small v-if="!editMode">
                    C. Operaciones Desc:
                    <strong class="white--text">{{
                      !employeeData.operationCenterDesc ||
                      employeeData.operationCenterDesc == ""
                        ? "NO ASIGNADO"
                        : employeeData.operationCenterDesc
                    }}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="6" class="grey--text text-center">
                  <v-autocomplete
                    v-if="editMode"
                    v-model="itemContent.company_person.working_group"
                    :items="groups"
                    return-object
                    label="Grupo Nominal"
                    dense
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item }">
                      <span>{{
                        item.name
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{
                        item.name
                      }}</span>
                    </template>
                  </v-autocomplete>
                  <small v-if="!editMode">
                    Grupo Nominal:
                    <strong class="white--text" v-if="!itemContent.company_person.working_group_id">NO ASIGNADO</strong>
                    <strong class="white--text" v-if="itemContent.company_person.working_group_id">{{itemContent.company_person.working_group.name}}</strong>
                  </small></v-col
                >
                <v-col cols="12" md="4" class="grey--text text-center">
                  <v-autocomplete
                    v-if="editMode"
                    v-model="itemContent.company_person.cost_center"
                    :items="costCenters"
                    return-object
                    label="Centro de Costos"
                    :filter="customFilterCostCenter"
                    dense
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item }">
                      <span>{{
                        item.code + " - " + item.name
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{
                        item.code + " - " + item.name
                      }}</span>
                    </template>
                  </v-autocomplete>
                  <small v-if="!editMode">
                    C. Costos:
                    <strong class="white--text" v-if="!itemContent.company_person.cost_center_id">NO ASIGNADO</strong>
                    <strong class="white--text" v-if="itemContent.company_person.cost_center_id">{{itemContent.company_person.cost_center.code +" - "+ itemContent.company_person.cost_center.name}}</strong>
                  </small></v-col
                >
                <v-col
                  v-if="roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND')"
                  cols="12"
                  md="4"
                  class="grey--text text-center"
                >
                  <small>
                    Antecedentes:
                    <strong class="white--text">{{
                      antecedentesUsuario.antecedentes === 0
                        ? "NO REGISTRA"
                        : antecedentesUsuario.antecedentes
                    }}</strong>
                  </small>
                </v-col>
                <v-col
                  v-if="roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND')"
                  cols="12"
                  md="4"
                  class="grey--text text-center"
                >
                  <small>
                    Inhabilidades:
                    <strong class="white--text">{{
                      antecedentesUsuario.inhabilidades === 0
                        ? "NO REGISTRA"
                        : antecedentesUsuario.inhabilidades
                    }}</strong>
                  </small>
                </v-col>
              </v-row>
          </v-row>
          <v-row
            v-if="roleChecker.iHaveGrants(userRole, 'REG-UPDATE')"
            class="px-10"
          >
            <v-col cols="12" md="6" v-if="!editMode">
              <v-btn
                color="primary"
                rounded
                large
                block
                @click="activateEditMode(itemContent)"
              >
                <i class="fal fa-edit mx-2"></i>
                EDITAR REGISTRO
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              <v-btn
                v-if="itemContent.company_person.agree_terms_id == true"
                color="error"
                rounded
                large
                block
                @click="dialogDelete = true"
              >
                <i class="fal fa-user-times mx-2"></i>
                DESACTIVAR REGISTRO
              </v-btn>
              <v-btn
                v-if="itemContent.company_person.agree_terms_id == false"
                color="success"
                rounded
                large
                block
                :loading="loadingActivation"
                @click="activateUser(itemContent)"
              >
                <i class="fal fa-user-check mx-2"></i>
                ACTIVAR REGISTRO
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-btn
                color="secondary"
                rounded
                large
                block
                :loading="loadingReg"
                @click="updateRegistry(itemContent)"
              >
                <i class="fal fa-save mx-2"></i>
                GUARDAR CAMBIOS
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-btn
                color="error"
                rounded
                large
                block
                :disabled="loadingReg"
                @click="
                  cancelEditMode(itemContent)
                "
              >
                <v-icon dark> mdi-cancel </v-icon> CANCELAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {S3} from "aws-sdk";
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";
import external from "../services/external-people";
import backlog from "../services/logs";
import xlsx from "xlsx";
import UserRegistry from "./user-registry.vue";
import ManageVehicles from "./manage-vehicles.vue";
import AdminLunch from "./restaurant/admin-lunch.vue";
import { v4 as uuidv4 } from "uuid";
import TakePhoto from "./utils/take-photo.vue"

export default {
  name: "Registrations",
  components: {
    UserRegistry,
    ManageVehicles,
    AdminLunch,
    TakePhoto
  },
  props: ['cedula_registro'],
  data: () => ({
    glbs: globals,
    loadingReport: false,
    loadingBulkUpdate: false,
    subOrg: "",
    roleChecker: roles,
    userRole: auth.getRoleName(),
    modules: auth.getModules(),
    peopleList: external.employeeList,
    accessLevels: ["0", "1", "2", "3"],
    employeeStatus: [
      { status: true, name: "ACTIVO" },
      { status: false, name: "INACTIVO" },
    ],
    activeInactive: [
      {id: true, name: "ACTIVAR"},
      {id: false, name: "INACTIVAR"},
    ],
    editMode: false,
    userOrgName: auth.getUserEnterpriseName(),
    datepickerIngreso: false,
    datepickerRetiro: false,
    todayDate: globals.COdate().substring(0, 10),
    organization: {},
    org: null,
    itemsList: [],
    loading: false,
    loadingReg: false,
    itemContent: null,
    itemContentVehicle: null,
    personLunch: null,
    gbls: globals,
    search: "",
    dialogQR: false,
    dialogCSV: false,
    dialogVehicle: false,
    dialogRestaurant: false,
    dialogDelete: false,
    showLunchBox: false,
    loadingActivation: false,
    headers: [
      {
        text: "",
        align: "center",
        value: "state",
        sortable: false,
      },
      {
        text: "CC",
        align: "center",
        value: "document_number",
        sortable: false,
      },
      { text: "Nombre", align: "center", value: "first_name", sortable: false },
      {
        text: "Apellido",
        align: "center",
        value: "first_last_name",
        sortable: false,
      },
      {
        text: "Fecha",
        align: "center",
        value: "company_person.created_at",
        sortable: false,
      },
      { text: "Hora", align: "center", value: "HoraIngreso", sortable: false },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    lunchHeaders: [
      { text: "CC", align: "center", value: "document_number", sortable: false },
      { text: "Cantidad Almuerzos", align: "center", value: "lunch_count", sortable: false },
    ],
    csvHeaders: [
      { text: "CC", align: "center", value: "document_number", sortable: false },
      { text: "Email", align: "center", value: "email", sortable: false },
      { text: "Celular", align: "center", value: "phone", sortable: false },
      { text: "Dirección", align: "center", value: "address", sortable: false, },
      //{ text: "Suborganización", align: "center", value: "suborganization", sortable: false },
      { text: "Cargo", align: "center", value: "position", sortable: false },
      { text: "Centro Costos", align: "center", value: "costCenter", sortable: false },
      // TODO: Arreglar forma de subida de fecha desde excel { text: "Fecha Retiro", align: "center", value: "disengagement_date", sortable: false },
    ],
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    loadingProfile: false,
    antecedentesUsuario: {
      antecedentes: 0,
      inhabilidades: 0,
    },
    counter: 0,
    cc_validation: "",
    employeeData: {
      position: "",
      operationCenter: "",
      operationCenterDesc: "",
      costCenter: "",
      costCenterDesc: "",
      ingresoDate: null,
      retiroDate: null,
      status: true,
      guestType: null,
      guestRoom: "",
      guestCheckinDate: "",
      guestCheckoutDate: "",
    },
    personTypes: ['EMPLEADO',
    'VISITANTE',
    'CONTRATISTA',
    'PROVEEDOR',
    'RESIDENTE'],
    genderTypes: [
    'F',
    'M'],
    RHTypes: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
    bulkSuborganization: null, 
    bulkWorkingGroup: null,
    bulkTypePerson: null,
    bulkActive: null,
    groups: [],
    bulkData: [],
    bulkLunchErrors: [],
    bulkErrors: [],
    bulkLunchData: [],
    lunch_data: [],
    costCenters: [],
    PSRegistryImg: "",
    registerImgBackup: "",
    datepickerBirthday: false,
    lunchModule: false,
    permissionGroupsList: [],
    permissionGroupsBL: [],
    suborgsList: [],
    last_survey: null,
    externalData: null,
    DOCUMENT_NUMBER_PATTERN: /^[0-9]*$/,
    EMAIL_PATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    selectedPG: null,
    dialogBL: false,
    blToAssign: null,
    rawPG: [],
    registryFilter: "Todos",
    registerStatusFilter: ["Todos", "Activos", "Restringidos", "Sin Permisos", "Lista Negra"],
    permissionGroupsRaw: [],
    blackListRaw: [],
    dialogBLDoubleValidation: false,
    datepickerCheckin: false,
    datepickerCheckout: false,
    guestTypes: [
      { id: "guest", name: "HUESPED" },
      { id: "visitor", name: "VISITANTE" },
      { id: "pool_visitor", name: "VISITANTE DIA SOL" },
    ],
    newImgUUID: "",
    changeImageDialog: false,
    selfieMode1: true,
    selfieMode2: false,
    loadingChangeProfileImgBtn: false,
    s3NewSelfieKey: "",
    selfieFileBase64: null,
    newSelfieImg: null,
    validFaceAnalysis: false,
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },  
  methods: {
    startProfileImgUpdate() {
      this.changeImageDialog = true;
      this.newImgUUID = uuidv4()
      if (this.newImgUUID == '' || this.newImgUUID == undefined || this.newImgUUID == null) {
        this.newImgUUID = uuidv4()
      }
    },

    clickNewSelfieInput() {
      this.$refs.newSelfieInput.click();
    },

    readNewSelfieFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.newSelfieImg = fileReader.result;
        this.resizeAndCompressBase64Img(this.newSelfieImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Img(base64) {
        let resizingFactor = 0.5;
        const canvas1 = document.createElement("canvas");
        const context1 = canvas1.getContext("2d");
        let img1 = document.createElement("img");
        img1.src = base64;

        const originalWidth = img1.width;
        const originalHeight = img1.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas1.width = canvasWidth;
        canvas1.height = canvasHeight;

        context1.drawImage(
            img1,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        ); 

        canvas1.toBlob((blob1) => {
            if (blob1) {
              this.selfieFileBase64 = blob1;
              this.createNewImage()
            } else {
              this.resizeAndCompressBase64Img(base64)
            }
        },
        "image/jpeg",
        0.6)
    },
    
    validateChangeImg(data) {
      if (data && data.key) {
        console.log(data)
        this.s3NewSelfieKey = data.key;
        this.analyzeNewProfileImg()
      }
    },

    createNewImage() {
      let name = "new_selfie_" + this.newImgUUID + ".jpg";

      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: name,
        Body: this.selfieFileBase64,
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err)
          this.loadingSelfieBtn = false
        }

        this.s3NewSelfieKey = data.key;
        this.analyzeNewProfileImg()
      });
    },

    analyzeNewProfileImg() {
      this.loadingChangeProfileImgBtn = true
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/analyze-person",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3NewSelfieKey,
          register_image: this.itemContent.register_image == '.jpg' ? null : this.itemContent.register_image,
        },
      }).then((res) => {
        if (res.data.type == "ok") {
          this.validFaceAnalysis = true;
          this.loadingChangeProfileImgBtn = false
          this.snackbar = true;
          this.snackbarColor = "success";
          this.snackbarText = res.data.message;
        } else {
          this.loadingChangeProfileImgBtn = false
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = res.data.message;
        }
      })
      .catch((err) => {
        console.log(err);
        this.loadingChangeProfileImgBtn = false
      });
    },

    changeProfileImg() {
      this.loadingChangeProfileImgBtn = true
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/update-profile-image",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3NewSelfieKey,
          IDNewSelfieImage: this.newImgUUID,
          register_image: this.itemContent.register_image == '.jpg' ? null : this.itemContent.register_image,
          person_id: this.itemContent.uuid,
          oldRekognition: this.itemContent.RekognitionId,
          label: this.itemContent.first_name + "_" + this.itemContent.first_last_name + "-" + this.itemContent.document_number
        },
      }).then((res) => {
        if (res.status == 200) {
          this.loadingChangeProfileImgBtn = false
          this.cleanChangeImgsModule()
          this.changeImageDialog = false;
          backlog.generateLog({
            enterprise: this.organization.name,
            username: auth.getUsername(),
            role: auth.getRoleName(),
            action: "update/registry/change-image",
            observation: this.itemContent.document_number ? this.itemContent.document_number : ""
          });
          this.getOneRegistry(this.itemContent, 'search')
          this.snackbarText = "Se actualizó su imagen de acceso exitosamente.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      })
      .catch((err) => {
        console.log(err);
        this.overlayLoading = false;
        this.loadingChangeProfileImgBtn = false
        this.overlayLoadingText = "";
      });
    },

    cleanChangeImgsModule() {
      this.newImgUUID = "";
      this.selfieMode1 = true;
      this.selfieMode2 = false;
      this.loadingChangeProfileImgBtn = false;
      this.s3NewSelfieKey = "";
      this.selfieFileBase64 = null;
      this.newSelfieImg = null;
      this.validFaceAnalysis = false;
    },

    imgErrorHandler(url) {
      console.log("Handler de imagen de registro", url)
      this.PSRegistryImg = this.registerImgBackup//"https://847395.smushcdn.com/1790738/wp-content/uploads/2015/09/imagen-no-encontrada.jpg?lossy=0&strip=1&webp=1"
      this.$forceUpdate()
    },

    markedRow(item) {
      if (this.itemContent) {
        if (item.uuid === this.itemContent.uuid) {
          return "selected-row";
        }
      }
    },

    customFilterCostCenter(item, queryText) {
      const name = (item.name) ? item.name.toLowerCase() : '';
      const code = (item.code) ? item.code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return (
        name.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1
      );
    },

    uploadFile(file) {
      let reader = new FileReader();
      var self = this
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = xlsx.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        self.bulkData = xlsx.utils.sheet_to_json(worksheet)
      };
      reader.readAsArrayBuffer(file);
    },

    uploadLunchFile(file) {
      let reader = new FileReader();
      this.bulkErrors = []
      var self = this
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = xlsx.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        self.bulkLunchData = []
        self.bulkLunchData = xlsx.utils.sheet_to_json(worksheet)
        console.log(self.bulkLunchData)
      };
      reader.readAsArrayBuffer(file);
    },

    asignCP(cedula) {
      for (let i = 0; i < this.itemsList.length; i++) {
        const element = this.itemsList[i];
        if (element.document_number == cedula) {
          return [element.uuid, element.company_person.uuid]
        }
      }

    },

    asignSedes(cedula) {
      for (let i = 0; i < this.itemsList.length; i++) {
        const element = this.itemsList[i];
        if (element.document_number == cedula) {
          return {
            sedes: element.company_person.Campus_company_person,
            person_id: element.uuid,
            company_person_id: element.company_person.uuid
          }
        }
      }

    },

    asignCostCenterID(costCenter) {
      for (let i = 0; i < this.costCenters.length; i++) {
        const element = this.costCenters[i];
        if (element.code === costCenter) {
          return element.uuid
        }
      }

    },

    bulkUpdate(bulkSuborganization, bulkWorkingGroup, bulkTypePerson, bulkActive) {
      this.bulkErrors = []
      this.loadingBulkUpdate = true;
      for (let i = 0; i < this.bulkData.length; i++) {
        const element = this.bulkData[i];
        element.CEDULA = element.CEDULA.toString();
        /* if (element.CEDULA.length == 7) {
          element.CEDULA = "000" + element.CEDULA;
        } else if (element.CEDULA.length == 8) {
          element.CEDULA = "00" + element.CEDULA;
        } else if (element.CEDULA.length == 9) {
          element.CEDULA = "0" + element.CEDULA;
        } */
        let IDs = this.asignCP(element.CEDULA);
        let CostID = this.asignCostCenterID(element.CENTRO_COSTOS);
        if (bulkSuborganization && bulkSuborganization != null) element.campus_id = bulkSuborganization;
        if (bulkWorkingGroup && bulkWorkingGroup != null) element.working_group_id = bulkWorkingGroup;

        if (bulkActive != null) {
          element.agree_terms_id = bulkActive
        }

        if (bulkTypePerson && bulkTypePerson != null) element.typePerson = bulkTypePerson;
        (IDs) ? (element.person_id = IDs[0], element.company_person_id = IDs[1]) : (element.person_id = null, element.company_person_id = null);
        (element.CEDULA) ? element.document_number = element.CEDULA : element.document_number = null;
        (element.EMAIL) ? element.email = element.EMAIL.toString().trim() : element.email = null;
        (element.CELULAR) ? element.phone = element.CELULAR.toString() : element.phone = null;
        (element.DIRECCIÓN) ? (element.DIRECCIÓN = element.DIRECCIÓN.toString(), element.address = element.DIRECCIÓN.toUpperCase()) : element.address = null;
        (element.CARGO) ? element.position = element.CARGO.toUpperCase() : element.position = null;
        (CostID) ? (element.costCenter = CostID) : (element.costCenter = null);
        // TODO: Arreglar forma de subida de fecha desde excel(element.FECHA_RETIRO) ? (element.FECHA_RETIRO = element.FECHA_RETIRO.toString(), element.disengagement_date = element.FECHA_RETIRO) : element.disengagement_date = null;

        delete element.CEDULA
        delete element.EMAIL
        delete element.CELULAR
        delete element.DIRECCIÓN
        delete element.CARGO
        delete element.CENTRO_COSTOS
        //delete element.FECHA_RETIRO

        if (element.person_id === null || element.company_person_id === null) {
          this.bulkErrors.push("La persona con cédula: " + element.document_number + " no se encuentra registrado en la plataforma, Fila: " + (i + 2))
        }

        if (element.email && this.EMAIL_PATTERN.test(element.email) == false) {
          this.bulkErrors.push("El correo: '" + element.email + "' no está formado correctamente, Fila: " + (i + 2))
        }

        if (element.phone && (element.phone.length < 10 || element.phone.length > 10)) {
          this.bulkErrors.push("El celular: '" + element.phone + "' no tiene la cantidad suficiente de dígitos, Fila: " + (i + 2))
        }

      }

      if (this.bulkErrors.length == 0) {
        axios
          .post(globals.APIURL + "registry/bulk-update", {
            campus_id: auth.getSelectedSede(),
            data: this.bulkData
          })
          .then((res) => {
            if (res.data && res.data == 'Bulk update successful') {
              backlog.generateLog({
                enterprise: this.organization.name,
                username: auth.getUsername(),
                role: auth.getRoleName(),
                action: "bulk-update/registry",
              });
              this.getRegistry("Todos")
              this.bulkData = []
              this.bulkErrors = []
              this.itemsList = []
              this.dialogCSV = false
              this.bulkSuborganization = null
              this.bulkWorkingGroup = null
              this.bulkTypePerson = null
              this.bulkActive = null
              this.itemContent = null;
              this.itemContentVehicle = null;
              this.org = null;
              this.subOrg = null;
              this.loadingBulkUpdate = false;
              this.snackbarText = "Se realizó la carga masiva de información.";
              this.snackbarColor = "success";
              this.snackbar = true;
            }
            this.loadingBulkUpdate = false;
          })
          .catch((err) => {
            this.loadingBulkUpdate = false;
            console.log(err.response);
          });
      } else {
        this.snackbarText = "Por favor revise las advertencias e intente nuevamente.";
        this.snackbarColor = "error";
        this.snackbar = true;
        this.loadingBulkUpdate = false
      }
    },

    bulkLunchUpdate() {
      this.bulkLunchErrors = []
      this.loadingBulkUpdate = true;
      for (let i = 0; i < this.bulkLunchData.length; i++) {
        const element = this.bulkLunchData[i];
        
        let personInfo = this.asignSedes(element.CEDULA);
        (personInfo != null) ? (element.person_info = personInfo) : (element.person_info = null);
        (element.CEDULA) ? element.document_number = element.CEDULA.toString() : element.document_number = null;
        (element.ALMUERZOS) ? element.lunch_number = element.ALMUERZOS.toString().trim() : element.lunch_number = "0";

        delete element.CEDULA
        delete element.ALMUERZOS

        if (element.person_info.person_id === null || element.person_info.company_person_id === null) {
          this.bulkErrors.push("La persona con cédula: " + element.document_number + " puede que no este en la sede seleccionada, Fila: " + (i + 2))
        }

        if (element.lunch_number && element.lunch_number == 0) {
          this.bulkErrors.push("Es necesario que la cantidad de almuerzos sea mayor a 0, Fila: " + (i + 2))
        }

      }

      //console.log(this.bulkLunchData)

      if (this.bulkErrors.length == 0) {
        let people_lunch_list = [];
        for (let i = 0; i < this.bulkLunchData.length; i++) {
          const eachPerson = this.bulkLunchData[i];
          for (let j = 0; j < eachPerson.person_info.sedes.length; j++) {
            const eachSede = eachPerson.person_info.sedes[j];
            
            people_lunch_list.push({document_number: eachPerson.document_number, sede_id: eachSede.campus.uuid, lunch_number: eachPerson.lunch_number})
          }
        }

        console.log("LISTA DE ALMUERZOS POR RESTAURANTE: ", people_lunch_list)
        axios({
          method: "POST",
          url: "https://tob9omblt1.execute-api.us-east-1.amazonaws.com/Prod/bulk-lunch/",
          data: JSON.stringify({company_id: auth.getUserEnterpriseID(), people_lunch_list: people_lunch_list}),
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            console.log(res)
            this.getRegistry("Todos")
            this.bulkLunchData = []
            this.bulkErrors = []
            this.itemsList = []
            this.dialogCSV = false
            this.itemContent = null;
            this.itemContentVehicle = null;
            this.org = null;
            this.subOrg = null;
            this.loadingBulkUpdate = false;
            this.snackbarText = "Se realizó la carga masiva de información.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.loadingBulkUpdate = false;
          console.log(err.response);
        });
      } else {
        this.snackbarText = "Por favor revise las advertencias e intente nuevamente.";
        this.snackbarColor = "error";
        this.snackbar = true;
        this.loadingBulkUpdate = false
      }
    },

    closeBulkDialog() {
      this.bulkData = []
      this.bulkLunchData = []
      this.bulkErrors = []
      this.dialogCSV = false
      this.bulkSuborganization = null
      this.bulkWorkingGroup = null
      this.bulkTypePerson = null
      this.loadingBulkUpdate = false;
    },

    activateEditMode(item, type) {
      if (!type || type == null || type == undefined) {
        this.editMode = true
      }
      this._beforeEditingCachePerson = Object.assign({}, item);
      this._beforeEditingCacheEmails = Object.assign({}, item.emails[item.emails.length - 1]);
      this._beforeEditingCachePhones = Object.assign({}, item.phones[item.phones.length - 1]);
      this._beforeEditingCacheAddresses = Object.assign({}, item.addresses[item.addresses.length - 1]);
      this._beforeEditingCachePosition = Object.assign({}, item.company_person.Positions[item.company_person.Positions.length - 1]);
      this._beforeEditingCacheCampusCompanyPerson = Object.assign([], item.company_person.Campus_company_person);
      this._beforeEditingCacheCompanyPerson = Object.assign({}, item.company_person);
      this._beforeEditingCacheExtraFields = Object.assign({}, this.employeeData);
    },

    cancelEditMode(item) {
      this.editMode = false
      Object.assign(item, this._beforeEditingCachePerson);
      Object.assign(item.emails[item.emails.length - 1], this._beforeEditingCacheEmails);
      Object.assign(item.phones[item.phones.length - 1], this._beforeEditingCachePhones);
      Object.assign(item.addresses[item.addresses.length - 1], this._beforeEditingCacheAddresses);
      Object.assign(item.company_person.Positions[item.company_person.Positions.length - 1], this._beforeEditingCachePosition);
      Object.assign(item.company_person.Campus_company_person, this._beforeEditingCacheCampusCompanyPerson);
      Object.assign(item.company_person, this._beforeEditingCacheCompanyPerson);
      Object.assign(this.employeeData, this._beforeEditingCacheExtraFields);
      this.asignItem(item, 'registry', true)
    },

    adminVehicles(item) {
      this.dialogVehicle = true;
      this.itemContentVehicle = item;
    },

    adminLunch(item) {
      this.dialogRestaurant = true;
      this.personLunch = item;
    },

    getWorkingGroups() {
      axios
        .post(globals.APIURL + "working-group/list-wg", {
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.groups = res.data
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getCostCenters() {
      axios
        .post(globals.APIURL + "working-group/all-cost-center", {
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.costCenters = res.data
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getOrganizations() {
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.organization = res.data;
            console.log(res.data)
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getDevices() {
      axios
        .post(globals.APIURL + "device/fb", { 
          filter: {
            campus_id: auth.getSelectedSede() 
          }
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];

              if (element.type_device == "restaurant") {
                this.lunchModule = true;
                this.$store.commit('asignLunchModuleStatus', true)
              }
              
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRegistry(registryFilter) {
      this.loading = true;
      if (this.cedula_registro) {
        /* this.cedula_registro.company_person = this.cedula_registro.company_person[0]
        this.asignItem(this.cedula_registro, 'registry')
        this.search = this.cedula_registro.document_number */
        this.search = this.cedula_registro.document_number
        this.getOneRegistry(this.cedula_registro, "search")
      }

      this.itemsList = []
      this.loading = true;
      let filter_type
      switch (registryFilter) {
        case "Activos":
           filter_type = "active"
          break;
        case "Restringidos":
           filter_type = "restricted"
          break;
        case "Sin Permisos":
           filter_type = "unassigned"
          break;
          case "Lista Negra":
            filter_type = "black_list"
          break;
        default:
          filter_type = "all"
          break;
      }

        axios// **NEW
        .post(globals.APIURL + "registry/all-people", {
          company_id: auth.getUserEnterpriseID(),
          filter: filter_type,
          black_lists: this.blackListRaw,
          p_groups: this.permissionGroupsRaw
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              let lunchData = this.lunch_data.filter(each_person => {return element.document_number == each_person.document_number });
              //console.log(lunchData)
              if (lunchData.length > 0) {
                element.lunch_number = lunchData[0].lunch_number
              } else {
                element.lunch_number = null
              }
            }
            this.itemsList = res.data;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data == "No documents were found") {
            this.loading = false;
          } else {
            this.getRegistry(registryFilter)
          }
        });
    },

    getLunchData() {
      axios({
        method: "GET",
        baseURL: "https://tob9omblt1.execute-api.us-east-1.amazonaws.com/Prod/lunch/" + auth.getSelectedSede(),
      }).then((data) => {
        this.lunch_data = data.data
        console.log(data.data)
      })
      .catch((err) => {
        console.log(err.response)
      });
    },

    asignItem(item, type, isCancelEdit) {
      this.itemContent = null;
      this.itemContentVehicle = null;
      this.org = null;
      this.subOrg = null;
      if (item) {
        if (type == "registry") {
          if (item.birthdate) item.birthdate = item.birthdate.substring(0, 10);
          this.getImg(item)
          this.itemContent = item;
          if (!item.emails || item.emails.length == 0) this.itemContent.emails.push({email: ''}) ; this.itemContent.emails = item.emails;
          if (!item.phones || item.phones.length == 0) this.itemContent.phones.push({phone: ''}) ; this.itemContent.phones = item.phones;
          if (!item.addresses || item.addresses.length == 0) this.itemContent.addresses.push({Address: ''}) ; this.itemContent.addresses = item.addresses;
          if (!item.company_person.Positions || item.company_person.Positions.length == 0) this.itemContent.company_person.Positions.push({position: ''}) ; this.itemContent.company_person.Positions = item.company_person.Positions;
          if (!item.company_person.suborgs_person || item.company_person.suborgs_person.length === 0) this.itemContent.company_person.suborgs_person.push({sub_org: {}}) ; this.itemContent.company_person.suborgs_person = item.company_person.suborgs_person;
          if (!item.company_person.Campus_company_person || item.company_person.Campus_company_person.length === 0) {
            this.itemContent.company_person.Campus_company_person = []
          } else {
            if (!isCancelEdit) {
              this.itemContent.company_person.Campus_company_person = item.company_person.Campus_company_person;
              let sedeList = []
              for (let i = 0; i < this.itemContent.company_person.Campus_company_person.length; i++) {
                sedeList.push(this.itemContent.company_person.Campus_company_person[i].campus)
              }
              this.itemContent.company_person.Campus_company_person = sedeList
            }
          }
          // TODO: Validar esta funcion cuando se termine todo ---> this.organizeOrg();
          this.getFields(item.uuid);
          let listOfPGs
          if (this.permissionGroupsBL.length > 0) {
            listOfPGs = this.rawPG
          } else {
            listOfPGs = this.permissionGroupsList
          }
          for (let j = 0; j < listOfPGs.length; j++) {
            const el = listOfPGs[j];
            
            if (item.company_person.pin == el.uuid) {
              item.company_person.pgData = el;
              this.selectedPG = item.company_person.pgData ? item.company_person.pgData : null;
            }
          }
          this.loadingProfile = false
          //if (this.roleChecker.iHaveGrants(this.userRole, "REGISTRY-BACKGROUND")) this.validateRecords(item.DocumentoUsuario);
        }
      }
    },

    getFields(personID) {
      axios
        .post(globals.APIURL + "registry/getFields", {
          filter: {
            person_id: personID,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            console.log(res.data.length)
            this.employeeData = res.data[0];
          } else {
            axios
              .post(globals.APIURL + "registry/createFields", {
                person_id: personID,
                position: "",
                operationCenter: "",
                operationCenterDesc: "",
                costCenter: "",
                costCenterDesc: "",
                ingresoDate: null,
                retiroDate: null,
                status: false,
                guestType: null,
                guestRoom: "",
                guestCheckinDate: "",
                guestCheckoutDate: "",
              })
              .promise()
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    statusEmployee(ingresoDate, retiroDate) {
      if (ingresoDate) {
        if (this.todayDate >= ingresoDate && !retiroDate) {
          return true;
        } else if (
          this.todayDate >= ingresoDate &&
          this.todayDate <= retiroDate
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    updateRegistry(item) {
      this.loadingReg = true
      let campusQueryBody = []
      let suborgBody = null;
      let visitorData = null;

      if (item.document_number && item.first_name && item.first_last_name) {
        if (item.document_number.length >= 6 && item.document_number.length <= 10 && this.DOCUMENT_NUMBER_PATTERN.test(item.document_number)  == true) {
          if (!item.company_person.disengagement_date || (item.company_person.disengagement_date.substring(0, 10) >= item.company_person.created_at.substring(0, 10))) {
            if (item.company_person.Positions[item.company_person.Positions.length - 1].position != this._beforeEditingCachePosition.position) {
              item.company_person.Positions.push({position: item.company_person.Positions[item.company_person.Positions.length - 1].position})
            }

            if (item.emails[item.emails.length - 1].email != this._beforeEditingCacheEmails.email) {
              item.emails.push({email: item.emails[item.emails.length - 1].email})
            }

            if (item.phones[item.phones.length - 1].phone != this._beforeEditingCachePhones.phone) {
              item.phones.push({phone: item.phones[item.phones.length - 1].phone})
            }

            if (item.addresses[item.addresses.length - 1].Address != this._beforeEditingCacheAddresses.Address) {
              item.addresses.push({Address: item.addresses[item.addresses.length - 1].Address})
            }

            // Permmit places new logic
            if (item.company_person.pin.places && item.company_person.pin.places.length > 0) {
              for (let i = 0; i < item.company_person.pin.places.length; i++) {
                const element = item.company_person.pin.places[i];
                
                campusQueryBody.push({campus_id: element, company_person_id: item.company_person.uuid})
              }

              if (item.company_person.pin.need_acceptance) {
                visitorData = {
                  uuid: item.company_person.uuid,
                  document_number: item.document_number,
                  company_id: auth.getUserEnterpriseID(),
                  sede_id: auth.getSelectedSede(),
                  allowed_entry: false
                }
              }
            }

            if (item.company_person.suborgs_person && item.company_person.suborgs_person.length > 0) {
             if (item.company_person.suborgs_person[0].sub_org.authorizers) {
                suborgBody = [{
                  sub_org_id: item.company_person.suborgs_person[0].sub_org.uuid,
                  company_person_id: item.company_person.uuid
                }]
             } 
            }

            // TODO: Old way for asign places, delete when new way works properlly
            // if (item.company_person.Campus_company_person.length > 0) {
            //   for (let i = 0; i < item.company_person.Campus_company_person.length; i++) {
            //     const element = item.company_person.Campus_company_person[i];
            //     
            //     campusQueryBody.push({campus_id: element.uuid, company_person_id: item.company_person.uuid})
            //   }
            // }

            let person_data = {
              document_number: item.document_number,
              first_name: item.first_name ? item.first_name.toUpperCase() : "",
              second_name: item.second_name ? item.second_name.toUpperCase() : "",
              first_last_name: item.first_last_name ? item.first_last_name.toUpperCase() : "",
              second_last_name: item.second_last_name ? item.second_last_name.toUpperCase() : "",
              email: item.email ? item.email : "",
              phone: item.phone ? item.phone : ""
            }

            if (item.rh && item.rh !== "") person_data.rh = item.rh;
            if (item.gender && item.gender !== "") person_data.gender = item.gender;
            if (item.birthdate && item.birthdate !== "" && item.birthdate.length == 10) {
              person_data.birthdate = item.birthdate + "T00:00:00.000Z"
            } else if (item.birthdate && item.birthdate !== "" && item.birthdate.length > 10) {
              person_data.birthdate = item.birthdate
            }
            console.log(visitorData)
            axios
                .put(globals.APIURL + "registry/ur", {
                  company_id: auth.getUserEnterpriseID(),
                  campus_id: auth.getSelectedSede(),
                  person_id: item.uuid,
                  update_person_obj: {
                    sensitive_data: person_data,
                    email: item.emails[item.emails.length - 1],
                    phone: item.phones[item.phones.length - 1],
                    address: item.addresses[item.addresses.length - 1],
                  },
                  visitor: visitorData,
                  suborgs: suborgBody,
                  company_person_id: item.company_person.uuid,
                  update_company_obj: {
                    campus: campusQueryBody,
                    position: item.company_person.Positions[item.company_person.Positions.length - 1],
                    type_person: item.company_person.type_person,
                    disengagement_date: item.company_person.disengagement_date ? item.company_person.disengagement_date + "T00:00:00.000Z" : null,
                    cost_center_id: item.company_person.cost_center != null && item.company_person.cost_center.uuid ? item.company_person.cost_center.uuid : null,
                    working_group_id: item.company_person.working_group != null && item.company_person.working_group.uuid ? item.company_person.working_group.uuid : null,
                    pin: item.company_person.pin.places && item.company_person.pin.places.length > 0 ? item.company_person.pin.uuid : item.company_person.pin
                  },
                })
                .then((res) => {
                  if (res && res.status == 200) {
                    if (res.data == "Documents were updated successful") {
                      backlog.generateLog({
                        enterprise: this.organization.name,
                        username: auth.getUsername(),
                        role: auth.getRoleName(),
                        action: "update/registry",
                        observation: item.document_number ? item.document_number : ""
                      });
                      this.getOneRegistry(item, 'update')
                      this.employeeData.status = this.statusEmployee(
                        item.company_person.created_at.substring(0, 10),
                        item.company_person.disengagement_date ? item.company_person.disengagement_date.substring(0, 10) : ''
                      );
                      axios
                        .post(globals.APIURL + "registry/updateFields", {
                          filter: {
                            person_id: item.uuid,
                          },
                          updateObj: this.employeeData,
                        })
                        .then((res) => {
                          if (res.data == "Fields were updated successful") {
                            // TODO: Agregar logica para guardar logs.
                            this.getFields(item.uuid)
                          }
                        })
                        .catch((err) => {
                          console.log(err.response);
                        });
                    }
                  }
                })
                .catch((err) => {
                  this.loadingReg = false
                  console.log(err.response);
                });
          } else {
            this.employeeData.status = this.statusEmployee(
              item.company_person.created_at.substring(0, 10),
              item.company_person.disengagement_date ? item.company_person.disengagement_date.substring(0, 10) : ''
            );
            this.snackbarText =
              "La fecha de retiro no puede ser menor que la fecha de ingreso.";
            this.snackbarColor = "red";
            this.snackbar = true;
            this.loadingReg = false
          }
        } else {
          this.snackbarText = "La cédula no puede ser menor de 6 ni mayor de 10 digitos y no debe llevar ningun tipo de texto.";
          this.snackbarColor = "red";
          this.snackbar = true;
          this.loadingReg = false
        }
      } else {
          this.snackbarText = "La cédula, el primer nombre y el primer apellido son datos obligatorios.";
          this.snackbarColor = "red";
          this.snackbar = true;
          this.loadingReg = false
      }
      
    },

    deleteRegistry(itemContent, documentText) {
      this.loadingReg = true
      itemContent.campus_id = auth.getSelectedSede()
      if (itemContent.document_number === documentText) {
        axios
          .post(globals.APIURL + "registry/dr", itemContent)
          .then((res) => {
            if (res && res.status == 200) {
              if (res.data == "Company person deleted successfuly") {
                /* for (let i = 0; i < this.itemsList.length; i++) {
                  const el = this.itemsList[i];
                  if (el.document_number === itemContent.document_number) {
                    this.itemsList.splice(i, 1);
                  }
                } */
                backlog.generateLog({
                  enterprise: this.organization.name,
                  username: auth.getUsername(),
                  role: auth.getRoleName(),
                  action: "deactivate/registry",
                  observation: itemContent.document_number ? itemContent.document_number : ""
                });
                this.snackbarText = "Se desactivó el registro de forma correcta";
                this.snackbarColor = "green";
                this.snackbar = true;
                this.dialogDelete = false;
                this.itemContent = null;
                this.itemContentVehicle = null;
                this.org = null;
                this.subOrg = null;
                this.search = "";
                this.cc_validation = ""
                this.loadingReg = false
                this.getOneRegistry(itemContent, 'search')
              }
            }
          })
          .catch((err) => {
            this.loadingReg = false
            console.log(err.response);
          });
      }
    },

    activateUser(itemContent) {
      this.loadingActivation = true
      itemContent.campus_id = auth.getSelectedSede()
      axios
        .post(globals.APIURL + "registry/activate-user", itemContent)
        .then((res) => {
          if (res && res.status == 200) {
            if (res.data == "Person activated successfuly") {
              backlog.generateLog({
                enterprise: this.organization.name,
                username: auth.getUsername(),
                role: auth.getRoleName(),
                action: "activate/registry",
                observation: itemContent.document_number ? itemContent.document_number : ""
              });
              this.snackbarText = "Se activo el usuario de forma correcta";
              this.snackbarColor = "green";
              this.snackbar = true;
              this.loadingActivation = false
              this.getOneRegistry(itemContent, 'search')
              this.getRegistry("Todos")
              /* this.dialogDelete = false;
              this.itemContent = null;
              this.itemContentVehicle = null;
              this.org = null;
              this.subOrg = null;
              this.search = "";
              this.cc_validation = ""
              this.loadingReg = false */
            }
          }
        })
        .catch((err) => {
          this.loadingActivation = false
          console.log(err.response);
        });
    },

    getOneRegistry(item, type) {
      this.itemContent = null;
      this.itemContentVehicle = null;
      this.org = null;
      this.subOrg = null;
      this.loadingProfile = true;
      this.getSurvey(item.document_number);
      this.filterExternalPerson(item.document_number)
      this.selectedPG = null
      axios
        .post(globals.APIURL + "registry/fo", {
          person_id: item.uuid,
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data.uuid) {
            //console.log("DATOS PERSONA: ",res.data)
            if (type === 'update') {
              this.snackbarText = "Se actualizaron los datos.";
              this.snackbarColor = "green";
              this.snackbar = true;
              this.dialogBL = false
              this.cc_validation = ""
            }
            this.editMode = false;
            this.loadingReg = false
            this.asignItem(res.data, "registry");
          }
        })
        .catch((err) => {
          this.loadingReg = false
          this.loadingProfile = false
          console.log(err.response);
        });
    },

    getNoSedeRegistry () {
      axios
        .post(globals.APIURL + "registry/fasl", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: ""
        })
        .then((res) => {
          this.$store.commit('asignRegistryNotifications', res.data)
        })
        .catch((err) => {
          if (err.response.data && err.response.data == "No documents were found") {
            this.$store.commit('asignRegistryNotifications', [])
          } else {
            console.log(err.response);
          }
        });
    },

    getImg(item) {
      this.PSRegistryImg = "";
      this.registerImgBackup = ""
      if (item) {
        if (item.register_image) {
          this.PSRegistryImg = globals.APIURL + "entry/registry-pic/" + item.register_image;
        } else {
          this.PSRegistryImg = globals.APIURL + "entry/entry-picture/" + this.organization.name + "/" + item.first_name + "/" + item.first_last_name + "/" + item.document_number;
        }

        if (item.entries.length > 0) {
          this.registerImgBackup = globals.APIURL + "entry/daily-pic/" + item.entries[0].image[0];
        }
      }
    },

    validateRecords(document) {
      axios
        .post(globals.APIURL + "identity/gant", {
          Document: document,
        })
        .then((res) => {
          if (res.data.antecedentes) {
            this.antecedentesUsuario.antecedentes =
              res.data.antecedentes.antecedentes.length;
            this.antecedentesUsuario.inhabilidades =
              res.data.antecedentes.inhabilidades.length;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];
            
            if (element.type_group == 'black_list') {
              this.blackListRaw.push(element.uuid)
              data.data.splice(i, 1)
            }
          }
          this.permissionGroupsList = data.data
          for (let k = 0; k < this.permissionGroupsList.length; k++) {
            const el = this.permissionGroupsList[k];
            this.permissionGroupsRaw.push(el.uuid)
          }
          this.getRegistry("Todos")
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getSuborgs() {
      axios
        .post(globals.APIURL + "campus/fdso", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          console.log(res)
          if (res.data && res.data.length > 0) {
            this.suborgsList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // TODO: Reposte de antecedentes no está actualizado.
    generateGrantReport(jsonContent) {
      if (jsonContent.length > 0) {
        this.loadingReport = true;

        for (let i = 0; i < jsonContent.length; i++) {
          const element = jsonContent[i];
          element.Antecedentes = 0;
          element.Inhabilidades = 0;
          delete element._id;
          delete element.IdUnico;
          delete element.RekognitionId;
          delete element.FechaIngreso;
          delete element.IdAcceso;
          delete element.Organizacion;
          delete element.SubOrganizacion;
          delete element.tipoDocumento;
          if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) == "0" &&
            element.DocumentoUsuario.charAt(2) == "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(3);
          } else if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) == "0" &&
            element.DocumentoUsuario.charAt(2) != "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(2);
          } else if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) != "0" &&
            element.DocumentoUsuario.charAt(2) != "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(1);
          }
          axios
            .post(globals.APIURL + "identity/gant", {
              Document: element.DocumentoUsuario,
            })
            .then((res) => {
              if (res.data.antecedentes) {
                this.counter++;
                if (res.data.antecedentes.antecedentes.length === 0) {
                  element.Antecedentes = "NO REGISTRA";
                } else {
                  element.Antecedentes =
                    res.data.antecedentes.antecedentes.length;
                }
                if (res.data.antecedentes.inhabilidades.length === 0) {
                  element.Inhabilidades = "NO REGISTRA";
                } else {
                  element.Inhabilidades =
                    res.data.antecedentes.inhabilidades.length;
                }

                if (this.counter === jsonContent.length) {
                  this.loadingReport = false;
                  backlog.generateLog({
                    enterprise: this.organization.name,
                    username: auth.getUsername(),
                    role: auth.getRoleName(),
                    action: "export-background/registry",
                  });
                  this.generateExcel(jsonContent);
                }
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      }
    },

    generateRegistryReport(data) {
      let reportContent = [];

      data.sort(function(a, b) {
        var c = new Date(a.company_person.created_at);
        var d = new Date(b.company_person.created_at);
        return c-d;
      });

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        let personInfo = {
          document_number: element.document_number,
          name: element.first_last_name + " " + (element.second_last_name ? element.second_last_name : "") + " " + element.first_name + " " + (element.second_name ? element.second_name : ""),
          status: element.company_person.registry_status && (element.company_person.registry_status === "active" ? "ACTIVO" : element.company_person.registry_status === "restricted" ? "RESTRINGIDO" : element.company_person.registry_status === "unassigned" ? "SIN PERMISOS" : element.company_person.registry_status === "black_list" ? "LISTA NEGRA" : "N/A"),
          register_date: element.company_person.created_at.substring(0, 10), 
          register_hour: element.company_person.created_at.substring(11, 19)
        }

        reportContent.push(personInfo);
      }

      this.formExcelFile(reportContent);
    },

    formExcelFile(jsonContent) {
      let newWB = xlsx.utils.book_new();
      let newWS = xlsx.utils.json_to_sheet(jsonContent, {
        header: [
          "document_number",
          "name",
          "status",
          "register_date",
          "register_hour"
        ],
      });

      newWS["A1"].v = "NRO. DOCUMENTO";
      newWS["B1"].v = "NOMBRE";
      newWS["C1"].v = "ESTADO";
      newWS["D1"].v = "FECHA REGISTRO";
      newWS["E1"].v = "HORA REGISTRO";

      xlsx.utils.book_append_sheet(newWB, newWS, "Registros");

      xlsx.writeFile(
        newWB,
        "VT - Registros a " +
          backlog.formatDate().substring(0, 10).replace(/\//gi, "-") +
          ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );
      this.snackbarText = "Se descargó su reporte de registros.";
      this.snackbarColor = "green";
      this.snackbar = true;
      backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/registry"})
    },

    generateExcel(jsonContent) {
      let newWB = xlsx.utils.book_new();
      let newWS = xlsx.utils.json_to_sheet(jsonContent, {
        header: [
          "DocumentoUsuario",
          "Nombre1",
          "Nombre2",
          "Apellido1",
          "Apellido2",
          "Birthdate",
          "Genero",
          "RH",
          "Antecedentes",
          "Inhabilidades",
        ],
      });

      newWS["A1"].v = "CC";
      newWS["B1"].v = "PRIMER NOMBRE";
      newWS["C1"].v = "SEGUNDO NOMBRE";
      newWS["D1"].v = "PRIMER APELLIDO";
      newWS["E1"].v = "SEGUNDO APELLIDO";
      newWS["F1"].v = "FECHA NACIMIENTO";
      newWS["G1"].v = "GENERO";
      newWS["H1"].v = "RH";
      newWS["I1"].v = "ANTECEDENTES";
      newWS["J1"].v = "INHABILIDADES";

      xlsx.utils.book_append_sheet(newWB, newWS, "Registros");

      xlsx.writeFile(
        newWB,
        "Registros-" +
          backlog.formatDate().substring(0, 10).replace(/\//gi, "-") +
          ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );
      this.snackbarText = "Se descargó su reporte de registros.";
      this.snackbarColor = "green";
      this.snackbar = true;
    },

    generateExcelGuide() {
        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet([{}], {
          header: [
            "CEDULA",
            "EMAIL",
            "CELULAR",
            "DIRECCIÓN",
            "CARGO",
            "CENTRO_COSTOS"
            //"FECHA_RETIRO" //TODO: Arreglar forma de subida de fecha desde excel
          ],
        });

        newWS["A1"].v = "CEDULA";
        newWS["B1"].v = "EMAIL";
        newWS["C1"].v = "CELULAR";
        newWS["D1"].v = "DIRECCIÓN";
        newWS["E1"].v = "CARGO";
        newWS["F1"].v = "CENTRO_COSTOS";
        //newWS["G1"].v = "FECHA_RETIRO"; //TODO: Arreglar forma de subida de fecha desde excel

        xlsx.utils.book_append_sheet(newWB, newWS, "Información");

        xlsx.writeFile(
          newWB,
          "Archivo Carga Masiva VS.xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        this.snackbarText = "Se descargó el archivo.";
        this.snackbarColor = "green";
        this.snackbar = true;
    },

    generateLunchExcelGuide() {
        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet([{}], {
          header: [
            "CEDULA",
            "ALMUERZOS",
          ],
        });

        newWS["A1"].v = "CEDULA";
        newWS["B1"].v = "ALMUERZOS";

        xlsx.utils.book_append_sheet(newWB, newWS, "Almuerzos");

        xlsx.writeFile(
          newWB,
          "Archivo Carga Masiva Restaurante.xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        this.snackbarText = "Se descargó el archivo.";
        this.snackbarColor = "green";
        this.snackbar = true;
    },

    getSurvey(document_number) {
      this.last_survey = null;
      axios({
        method: "POST",
        url: globals.APIURL + "forms/find-last-surveys",
        data: {
          company_id: auth.getUserEnterpriseID(),
          document_number: document_number,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.last_survey = res.data[res.data.length - 1]
          }
        }
      }).catch(err => {
        console.log(err)
      });
    },

    getBlackLists() {
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/get-black-lists",
        data: {
          company_id: auth.getUserEnterpriseID(),
          type_group: "black_list"
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.permissionGroupsBL = res.data
          const allPG = [...this.permissionGroupsList, ...this.permissionGroupsBL]
          this.rawPG = allPG
          /* let blGroups = res.data
          for (let index = 0; index < blGroups.length; index++) {
            const el = blGroups[index];
            let unitedName = "LN - " + el.name;
            el.name = unitedName;
          }
          this.permissionGroupsList = blGroups.concat(this.permissionGroups) */
        }
      })
      .catch((err) => {
        console.log(err)
      });
    },
      
    assignBL(item) {
      this.updateRegistry(item)
    },

    filterExternalPerson(document_number) {
      this.externalData = null;

      if (document_number !== '' && document_number.charAt(0) == "0" && document_number.charAt(0) == "0") {
        document_number = document_number.substring(2, document_number.length + 1)
      }

      let filteredUsers = this.peopleList.filter((user) => {
        return user.document === document_number;
      });

      if (filteredUsers.length > 0) {
        if (filteredUsers[0].fechaIngreso !== '') {
          filteredUsers[0].fechaIngreso = filteredUsers[0].fechaIngreso.substring(0, filteredUsers[0].fechaIngreso.indexOf(' '))
        }
        this.externalData = filteredUsers[0];
      }
    }
  },
  mounted() {
    this.getSuborgs()
    this.getPremissionGroups()
    this.getBlackLists()
    //this.getLunchData()
    this.getOrganizations();
    this.getDevices();
    this.getWorkingGroups();
    this.getCostCenters();
    this.getNoSedeRegistry()

    this.$bus.$on("updateRegistry", () => {
      this.getRegistry("Todos");
    });
  },
};
</script>
<style>
.bulkErrorBox {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100px;
}

.group-sedes {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 130px;
}

.sede-chip {
  margin: 4px 8px 4px 0 !important;
}

.rounded-edges {
  border-radius: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

table {
  border-collapse: collapse;
}

.selected-row {
  background-color: #d3d3d385;
  transform: scale(1.01);
}

tr:hover {
  background-color: #d3d3d385;
  transform: scale(1.01);
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>